import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styles from "./AppLayout.scss";
import { Spinner } from "react-bootstrap";
import HomeBannerSection from "./HomeBannerSection";
import InnerBannerSection from "./InnerBannerSection";
import ContactSection from "./ContactSection";
import SubscriptionSection from "./SubscriptionSection";
import { fetchMetaData } from "../../actions";
import { formatCompanySettings } from "./utils";
import SimpleButton from "../Buttons/SimpleButton";

import ReactGA from "react-ga4";
import {
  CURRENT_CURRENCY_CHANGED,
  SUPPORTED_CURRENCIES_LOADED,
} from "../../reducers/types";

function AppLayout(props) {
  const { loading, error, companySettings, meta_loaded } = useSelector(
    (state) => state.meta
  );

  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    if (!meta_loaded) {
      fetchMetaData(dispatch, userState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta_loaded]);

  useEffect(() => {
    const supportedCurrencies = companySettings.find(
      (cs) => cs.key === "supported_currencies"
    );

    const gaTrackingId = companySettings.find(
      (elem) => elem.key === "google_analytics_tracking_id"
    );

    if (gaTrackingId) {
      ReactGA.initialize(gaTrackingId.value);
      ReactGA.send("pageview");
    }

    if (supportedCurrencies) {
      dispatch({
        type: SUPPORTED_CURRENCIES_LOADED,
        payload: JSON.parse(supportedCurrencies.value),
      });
    }

    let defaultCurrency = {};
    let currentCurrency = {};

    let defaultCurrencyObj = companySettings.find(
      (cs) => cs.key === "default_currency"
    );

    if (defaultCurrencyObj) {
      defaultCurrency = JSON.parse(defaultCurrencyObj.value);
    }

    // checking from the local storage first.
    let currentCurrencyStr = localStorage.getItem("current_currency");

    if (currentCurrencyStr) {
      currentCurrency = JSON.parse(currentCurrencyStr);
    }

    let appliedCurrency = currentCurrency.code
      ? currentCurrency
      : defaultCurrency;

    if (appliedCurrency.code) {
      dispatch({ type: CURRENT_CURRENCY_CHANGED, payload: appliedCurrency });
    }
  }, [dispatch, companySettings]);

  if (loading) {
    return (
      <div className="flex py-5 justify-center items-center mt-3">
        <div className="py-5">
          <Spinner animation="grow" variant="app" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="py-5 mt-3">
        <div className="flex py-5  justify-center items-center">
          <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
          <SimpleButton
            onClick={() => window.location.reload()}
            title="Please try again"
          />
        </div>
      </div>
    );
  }

  const settings = formatCompanySettings(companySettings);

  let bannerSection = null;
  let InnerbannerSection = null;
  if (!props.hideBanner) {
    bannerSection = (
      <div>
        <HomeBannerSection
          companyContactNumber={settings.companyContactNumber}
        />
      </div>
    );
  }
  if (props.hideBanner) {
    InnerbannerSection = (
      <div>
        <InnerBannerSection
          companyContactNumber={settings.companyContactNumber}
        />
      </div>
    );
  }

  let contactSection = null;

  if (!props.hidePhoneDetail) {
    contactSection = (
      <ContactSection
        companySettings={companySettings}
        companyContactNumber={settings.companyContactNumber}
      />
    );
  }

  let subscribeSection = null;
  if (!props.hideSubscribeSection) {
    subscribeSection = (
      <div className={`flex items-center ${styles.textblock1}`}>
        <SubscriptionSection />
      </div>
    );
  }

  return (
    <>
      {bannerSection}
      {InnerbannerSection}
      {contactSection}
      {props.children}
      {subscribeSection}
    </>
  );
}

export default React.memo(AppLayout);