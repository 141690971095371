import { server_url, showMessage } from "./constants";
import axios from "axios";
import {
  ORDER_DETAILS_ERROR,
  ORDER_DETAILS_LOADED,
  ORDER_DETAILS_LOADING,
  ORDER_CHECKOUT_LOADED,
  ORDER_CHECKOUT_LOADING,
  ORDER_CHECKOUT_ERROR,
  ORDER_ATTACHMENT_LOADING,
  ORDER_ATTACHMENT_LOADED,
  ORDER_ATTACHMENT_ERROR,
  COURIER_REGIONS_LOADING,
  COURIER_REGIONS_LOADED,
  COURIER_REGIONS_ERROR
} from "../reducers/types";

export const callOrdersApi = async (dispatch, token, cartData) => {
  try {
    dispatch({ type: ORDER_DETAILS_LOADING });
    let payload = {}
    dispatch({ type: ORDER_DETAILS_LOADED, payload });
  } catch (err) {
    console.log(err);
    dispatch({ type: ORDER_DETAILS_ERROR });
  }
};

export const callOrdersCheckoutApi = async (dispatch, token, requestBody) => {
  try {
    dispatch({ type: ORDER_CHECKOUT_LOADING });

    const response = await axios.post(
      `${server_url}/orders/checkout`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: ORDER_CHECKOUT_LOADED, payload: response.data });
  } catch (err) {

    if (err && err.response && err.response.data && err.response.data.message) {
      if (err?.response?.data?.message?.includes('invalid credentials')) {
        showMessage("Session expired! Pls log in again.", "danger");
      } else {
        showMessage(err?.response?.data?.message, "danger");
      }
    } else {
      showMessage(err?.message ? err.message : 'something went wrong!', "danger");
    }
    dispatch({ type: ORDER_CHECKOUT_ERROR });
  }
};

export const uploadOrdersAttachment = async (dispatch, token, formData) => {

  try {
    dispatch({ type: ORDER_ATTACHMENT_LOADING });
    const response = await axios.post(
      `${server_url}/orders/attachment`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "multipart/form-data",
        },
      }
    );
    dispatch({ type: ORDER_ATTACHMENT_LOADED, payload: response.data });
  } catch (err) {
    if (err && err.response && err.response.data && err.response.data.message) {
      alert(err.response.data.message);
    }
    dispatch({ type: ORDER_ATTACHMENT_ERROR });
  }
};

export const verifyPayment = async (token, data) => {
  return axios.post(`${server_url}/orders/verify_payment`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchOrders = (token) => {
  return axios.get(`${server_url}/orders?sort=-id&per-page=1000`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchOrderDetails = (token, orderId) => {
  return axios.get(`${server_url}/orders/order_details?orderId=${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchOrderItems = (token, orderId) => {
  return axios.get(`${server_url}/orders/items?orderId=${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPaymentGateways = async (token) => {
  return axios.get(`${server_url}/orders/payment_gateways?sort=display_order`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchCourierRegions = async (dispatch, token) => {
  try {
    dispatch({ type: COURIER_REGIONS_LOADING });
    const response = await axios.get(
      `${server_url}/couriers_regions`, {
      withCredentials: false,
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: COURIER_REGIONS_LOADED, payload: response.data });
  } catch (err) {
    dispatch({ type: COURIER_REGIONS_ERROR });
  }
};