import {
    PRODUCTS_LOADING,
    PRODUCTS_ERROR,
    PRODUCTS_LOADED,
    FEATURED_PRODUCTS_LOADED,
    FEATURED_PRODUCTS_ERROR,
    FEATURED_PRODUCTS_LOADING,
} from './types';

const defaultState = {
    data: [],
    loading: true,
    error: false,
    featured: [],
};

export default function products(state = defaultState, action) {
    switch (action.type) {
        case PRODUCTS_LOADING:
            return { ...state, loading: true, error: false, data: [] };
        case PRODUCTS_ERROR:
            return { ...state, error: false, loading: false, data: [] };
        case PRODUCTS_LOADED:
            return { ...state, error: false, loading: false, data: action.payload };
        case FEATURED_PRODUCTS_LOADING:
            return { ...state, loading: true, error: false, featured: [] };
        case FEATURED_PRODUCTS_ERROR:
            return { ...state, error: false, loading: false, featured: [] };
        case FEATURED_PRODUCTS_LOADED:
            return { ...state, error: false, loading: false, featured: action.payload };
        default:
            return state;
    }
}
