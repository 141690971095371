import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchInnerBannerContent } from "../../../actions";
import { image_server } from "../../../actions/constants";
import { Carousel } from "react-bootstrap";
import styles from './index.module.scss'
import Breadcrumbs from "../Breadcrumbs";

const InnerBannerSection = () => {
  const [banners, setBanners] = useState([]);
  // const breadcrumb = useSelector((state) => state.breadcrumb.data);
  const asyncCallsOnLoad = useCallback(async () => {
    try {
      const response = await fetchInnerBannerContent();
      setBanners(response.data);
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  }, []);

  useEffect(() => {
    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);
  return (
    <>
      <Carousel controls={false} interval={3000} >
        {banners.map((banner) => {
          return (
            <Carousel.Item key={banner.id}>
              <Link to={banner.url}>
                <img
                  alt={banner.name}
                  className={styles.bannerImg}
                  src={image_server + `/banners/${banner.image}`}
                />
              </Link>
            </Carousel.Item>
          );
        })}
      </Carousel>
      <div className={``}>
        {<Breadcrumbs />}
      </div>
    </>
  );
};

export default InnerBannerSection;
