import React, { useState, useEffect, useCallback } from "react";
import AppLayout from "../../components/AppLayout";
import AppLoader from "../../components/AppLoader";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { validEmail, getErrorMsg } from "../../utils";
import { UpdateUserDetails, fetchUserDetails } from "../../actions/users";
import { USER_DETAILS_FETCHED } from "../../reducers/types";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../actions/breadcrumb";

function Profile() {
  const [isUpdateInitiated, setIsUpdateInitiated] = useState(false);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");
  const userState = useSelector((state) => state.user);
  const cartState = useSelector((state) => state.cart);
  const dispatch = useDispatch();
  const [values, setValues] = useState({
    name: "",
    mobile_number: "",
    additional_mobile_number: "",
    email: "",
    gender: "",
  });

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / My Profile
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);


  useEffect(() => {
    if (userState.userDetails) {
      setValues({
        name: userState.userDetails.name,
        mobile_number: userState.userDetails.mobile_number,
        additional_mobile_number:
          userState.userDetails.additional_mobile_number,
        email: userState.userDetails.email,
        gender: userState.userDetails.gender,
      });
    }
  }, [userState.userDetails]);

  const handleChange = useCallback((field, e) => {
    const { value } = e.target;
    setErrorText("");
    setSuccessText("");
    setValues((currValues) => {
      return {
        ...currValues,
        [field]: value,
      };
    });
  }, []);

  const handleGenderChange = useCallback((e) => {
    const { value } = e.target;
    setValues((currValues) => {
      return {
        ...currValues,
        gender: value,
      };
    });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (
        values.email &&
        values.email.trim().length &&
        !validEmail(values.email)
      ) {
        setErrorText("Please enter a valid Email!");
        return false;
      }
      if (
        values.additional_mobile_number &&
        values.additional_mobile_number.trim().length &&
        (values.additional_mobile_number.trim().length !== 10 ||
          isNaN(values.additional_mobile_number))
      ) {
        setErrorText("Please enter a valid alternate mobile number");
        return false;
      }
      setIsUpdateInitiated(true);
      try {
        const { name, email, additional_mobile_number, gender } = values;
        await UpdateUserDetails(
          {
            name,
            email,
            additional_mobile_number,
            gender,
            cart_data: cartState.data.length
              ? JSON.stringify(cartState.data)
              : "",
          },
          userState.data.token
        );
        const userDetailResponse = await fetchUserDetails(userState.data.token);
        const userDetails = { ...userDetailResponse.data };
        delete userDetails.cart_data;
        dispatch({ type: USER_DETAILS_FETCHED, payload: userDetails });
        setSuccessText("Profile Updated Successfully");
        setIsUpdateInitiated(false);
      } catch (err) {
        console.log(err);
        setIsUpdateInitiated(false);
        setErrorText(getErrorMsg(err));
      }
    },
    [userState.data.token, values, dispatch, cartState.data]
  );

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {isUpdateInitiated ? <AppLoader /> : null}
      <div className="myContainer">
      <div className="contact-sec text-center">
        {/* <div className="text-2xl font-bold">My Profile</div> */}
        <h3>My Profile</h3>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Control
              value={values.name || ""}
              onChange={handleChange.bind(this, "name")}
              className="mt-2"
              type="text"
              placeholder="Name"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              value={values.email || ""}
              onChange={handleChange.bind(this, "email")}
              className="mt-2"
              type="text"
              placeholder="Email"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              value={values.mobile_number}
              onChange={() => {}}
              disabled
              className="mt-2"
              type="text"
            />
          </Form.Group>
          <Form.Group>
            <Form.Control
              value={values.additional_mobile_number}
              onChange={handleChange.bind(this, "additional_mobile_number")}
              className="mt-2"
              type="text"
              placeholder="Alternate Mobile Number"
            />
          </Form.Group>
          <Form.Group className="flex justify-content-center my-4">
            <Form.Check
              className="mr-4"
              onChange={handleGenderChange}
              checked={values.gender === "male"}
              type="radio"
              label={`Male`}
              value="male"
            />
            <Form.Check
              className="mr-4"
              onChange={handleGenderChange}
              checked={values.gender === "female"}
              type="radio"
              label={`Female`}
              value="female"
            />
            <Form.Check
              className="mr-4"
              onChange={handleGenderChange}
              checked={values.gender === "unknown"}
              type="radio"
              label={`Unknown`}
              value="unknown"
            />
          </Form.Group>
          {/* <Form.Group>
            <Form.Control
              as="textarea"
              value={values.message}
              onChange={handleChange.bind(this, "message")}
              className="mt-8"
              type="text"
              placeholder="Enter your message"
            />
          </Form.Group> */}
          {errorText.length ? (
            <div className="mb-4 text-red-400 font-semibold">{errorText}</div>
          ) : null}
          {successText.length ? (
            <div className="mb-4 text-green-400 font-semibold">
              {successText}
            </div>
          ) : null}
          <SimpleButton title="Update" />
        </Form>
        </div>
      </div>
    </AppLayout>
  );
}

export default Profile;
