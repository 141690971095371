import React, { useState, useCallback } from "react";
import { Form, Row, Col, Button, Container, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserDetails, verifyEmail } from "../../actions/users";
import { getErrorMsg } from "../../utils";
import { useDispatch } from "react-redux";
import { USER_LOGGED_IN, USER_DETAILS_FETCHED } from "../../reducers/types";
import AppLayout from "../../components/AppLayout";
import { showMessage } from "../../actions/constants";
import AppLoader from "../../components/AppLoader";
import ResendOtp from "../ResendOtp";

function VerifyEmail() {
  const [isSubmissionInitiated, setIsSubmissionInitiated] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [resendLoading, setResendLoading] = useState(false);

  let email = null;

  const handleFieldChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setFormErrors({});

      if (!formData?.otp?.length) {
        setFormErrors({ otp: "Please Enter Otp" });
        return false;
      }
      if (formData.otp.length < 6) {
        setFormErrors({ otp: "Invalid OTP Value." });
        return false;
      }

      setIsSubmissionInitiated(true);

      try {
        const response = await verifyEmail(email, formData.otp);
        dispatch({ type: USER_LOGGED_IN, payload: response.data });
        const userDetailResponse = await fetchUserDetails(response.data.token);
        dispatch({
          type: USER_DETAILS_FETCHED,
          payload: userDetailResponse.data,
        });
        navigate("/");
        showMessage("Logged in successfully.", "success");
      } catch (err) {
        console.log(err.response);
        setIsSubmissionInitiated(false);
        showMessage(getErrorMsg(err), "danger");
      }
    },
    [email, navigate, formData, dispatch]
  );

  if (!location.state || !location.state.email) {
    navigate("/signup");
    return true;
  }

  email = location.state.email;

  const errorField = (message) => {
    return message ? (
      <div className="mt-3 mb-2 ml-1 text-red-500 text-center font-semibold">
        {message}
      </div>
    ) : null;
  };

  let otpForm = (
    <>
      {/* {JSON.stringify(formData)} */}
      {isSubmissionInitiated ? <AppLoader /> : null}
      <div className="form-sec otp-sec">
        <div className="text-center">
          <h3 className="text-dark">Verify Otp</h3>
        </div>

        <div className="my-3 text-center">
          <p>
            Enter the OTP sent to your email <span>{location.state.email}</span>
          </p>
        </div>
        <Form onSubmit={handleFormSubmit}>
          <Form.Control
            type="text"
            placeholder="Enter your OTP here"
            className="otp-input"
            value={formData.otp}
            maxLength="8"
            onChange={(e) => handleFieldChange(e, "otp")}
          />

          {errorField(formErrors.otp)}

          <div className="text-center">
            <Button className="my-btn" type="submit">
              Verify
            </Button>
          </div>
        </Form>
        {resendLoading ? (
          <div>
            <Spinner animation="border" role="status" size="sm" />
          </div>
        ) : (
          <div className="resend-block">
            <ResendOtp
              email={location.state.email}
              maxTime={60}
              setResendLoading={setResendLoading}
            />
          </div>
        )}
      </div>
    </>
  );

  return (
    <AppLayout hideBanner={true}>
      <Container className=" mt-8 mb-8">
        <Row className="justify-content-md-center">
          {/* <Col md={4} sm={8} ><img className="mt-10 float-right" src="/images/deals.jpg" /></Col> */}
          <Col md={6} sm={12} className="justify-center">
            {otpForm}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
}

export default VerifyEmail;
