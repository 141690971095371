import React from "react";
import { useSelector } from "react-redux";
import "./Breadcrumb.css";

const Breadcrumbs = () => {
  const breadcrumb = useSelector((state) => state.breadcrumb.data);
  return breadcrumb ? (
    <div className="my-breadcrumb">
      <div className="myContainer">
        {/* <div className="content">{breadcrumb}</div>   */}
      </div>
    </div>
  ) : null;
};

export default Breadcrumbs;
