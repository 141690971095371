import React from 'react';
import { useSelector } from 'react-redux';

import * as Icons from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAmount } from "../utils";

function Amount({ amount }) {

    const currency = useSelector(state => state.meta.currentCurrency);

    return (
        <span style={{ 'whiteSpace': 'nowrap' }} >
            <FontAwesomeIcon
                fontSize="16px"
                icon={Icons[currency?.icon]}
            /> {getAmount(amount, currency)}
        </span>
    )
}

export default Amount;