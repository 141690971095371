import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { slug, image_server, server_url } from "../../actions/constants";
import { useDispatch, useSelector } from "react-redux";
import AddCartButton from "../../components/AddCartButton";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// import Paginations from "./Pagination";
import "./Product.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Amount from "../../components/Amount";
import InfiniteScroll from "react-infinite-scroll-component";
import { setBreadcrumb } from "../../actions/breadcrumb";

function ProductList({ productsData, categoryId }) {
  const cartData = useSelector((state) => state.cart.data);
  const [items, setItems] = useState(productsData);
  const [page, setPage] = useState(2);
  const [noMore, setNoMore] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categories = useSelector((state) => state.categories.data);
  const findActiveCate = categories?.find(
    (cate) => cate.id === parseInt(categoryId)
  )?.name;

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link to="/Categories">/ Categories</Link>
        <Link className="active" to="#">/ {findActiveCate}</Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch, findActiveCate]);


  // const [currentPage, setCurrentPage] = useState(1);
  // let NUM_OF_RECORDS = productsData.length;
  // let LIMIT = 1000;

  // const onPageChanged = useCallback(
  //   (event, page) => {
  //     event.preventDefault();
  //     setCurrentPage(page);
  //   },
  //   [setCurrentPage]
  // );
  // const currentData = productsData.slice(
  //   (currentPage - 1) * LIMIT,
  //   (currentPage - 1) * LIMIT + LIMIT
  // );

  if (!productsData.length) {
    return (
      <div className="flex h-64 justify-center items-center w-full">
        <div className="font-bold mr-2">
          No products associated to this category.
        </div>
      </div>
    );
  }

  const fetchProducts = async () => {
    const res = await axios.get(
      `${server_url}/products?ProductsSearch[category_id]=${categoryId}&per-page=20&page=${page}`
    );

    const data = await res.data;
    return data;
  };

  const fetchData = async () => {
    const productFromServer = await fetchProducts();
    setItems([...items, ...productFromServer]);

    if (productFromServer.length === 0 || productFromServer.length < 20) {
      setNoMore(false);
    }
    setPage(page + 1);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetchData}
        hasMore={noMore}
        loader={
          <div className="mt-2 text-center">
            <Spinner animation="grow" variant="app" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        }
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <div className="row pt-2 ">
          {items.map((elem) => {
            return (
              <div key={elem.id} className="col-md-4 col-sm-6 my-col">
                <Card className={`main-card cursor-pointer`}>
                  <LazyLoadImage
                    alt={elem.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      minHeight: "230px",
                    }}
                    effect="blur"
                    className="product-img"
                    src={
                      image_server +
                      `/products/${elem.id}/230x230-${elem.main_image}`
                    }
                    onClick={() =>
                      navigate(`/product/${elem.id}/${slug(elem.name)}`)
                    }
                  />

                  <Card.Body className="card-body">
                    <div
                      className="my-card-title"
                      onClick={() =>
                        navigate(`/product/${elem.id}/${slug(elem.name)}`)
                      }
                    >
                      {elem.name.toUpperCase()}
                    </div>
                    <div className="my-card-price">
                      {elem.selling_price !== elem.mrp && (
                        <del className="mrp-price">
                          <Amount amount={elem.mrp} />
                        </del>
                      )}
                      <Amount amount={elem.selling_price} />
                    </div>
                    <AddCartButton elem={elem} cartData={cartData} />
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>

      {/* <div className="product-pagination">
          <div className="product-quantity">
            <h5>{productsData.length} Products of 10 pages</h5>
          </div>
          <div className="pagination-wrapper">
            <Paginations
              totalRecords={NUM_OF_RECORDS}
              pageLimit={LIMIT}
              pageNeighbours={2}
              onPageChanged={onPageChanged}
              currentPage={currentPage}
            />
          </div>
        </div> */}
    </>
  );
}

export default ProductList;
