import React, { useState, useCallback, useEffect } from 'react';
import AppLayout from "../../components/AppLayout";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import AppLoader from "../../components/AppLoader";
import { signupUser } from "../../actions/users";
import { getErrorMsg } from "../../utils";
import { showMessage } from '../../actions/constants';
import { setBreadcrumb } from '../../actions/breadcrumb';
import { useDispatch } from 'react-redux';



// x: 1: folder name was Signup but function name was SignUp which was wrong. The case (upper/lower) must also be exactly same.
export default function SignUp() {
  const [isSignUpInitiated, setIsSignUpInitiated] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFieldChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Sign Up
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);


  useEffect(() => {
    window.scrollTo({
        top: 150,
        behavior: "smooth"
      });
    ;
}, []);

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setFormErrors({});
      if (formData.password.length < 8) {
        // x: In which field we are setting? Check the login form error msg properly. formData is an object and not the string.
        setFormErrors({ confirmPassword: 'Password must be atleast 8 characters long!' });
        return false;
      }
      // x: Renamed confPassword to confirmPassword, never use shortcut name in functions or variables.
      if (formData.password !== formData.confirmPassword) {
        // setFormErrors(`Passwords doesn't match`);
        setFormErrors({ confirmPassword: "Passwords doesn't match" });
        return false;
      }
      setFormErrors("");
      setIsSignUpInitiated(true);
      try {
        await signupUser(formData);
        navigate("/verify-email", {
          state: { email: formData.email },
        });
      } catch (err) {
        console.log(err.response);
        setIsSignUpInitiated(false);
        showMessage(getErrorMsg(err), "danger");
      }
    },
    [formData, navigate]
  );

  const errorField = (message) => {
    return message ? (<div className="mt-3 mb-2 ml-1 text-red-500 text-center font-semibold">{message}</div>) : null;
  }

  let content = (
    <>
      {/* {JSON.stringify(formData)} */}

      {isSignUpInitiated ? <AppLoader /> : null}
      <div className="form-sec signup-sec-main">
        <div className="text-center">
          <h3 className="text-dark">Sign Up</h3>
        </div>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group as={Row} controlId="formPlaintextEmail" className="align-items-center mb-3">
            <Form.Label column sm="3">
              {/* x: put * on all 3 fields because all are required. */}
              Email*
            </Form.Label>
            <Col sm="9">
              <Form.Control type="email" placeholder="Enter email" value={formData.email} required={true}
                onChange={e => handleFieldChange(e, 'email')} />
            </Col>
            {errorField(formErrors.email)}
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextPassword" className="align-items-center mb-3">
            <Form.Label column sm="3">
              Password*
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" placeholder="Password" value={formData.password} required={true}
                onChange={e => handleFieldChange(e, 'password')} />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextPassword" className="align-items-center mb-4">
            <Form.Label column sm="3">
              Confirm Password*
            </Form.Label>
            <Col sm="9">
              <Form.Control type="password" placeholder="Confirm Password" value={formData.confirmPassword} required={true}
                onChange={e => handleFieldChange(e, 'confirmPassword')} />
            </Col>
            <div className="text-center mx-auto my-0">
              {errorField(formErrors.confirmPassword)}
            </div>
          </Form.Group>
          <div className="text-center">
            <Button className="my-btn" type="submit" >
              Countinue
            </Button>
          </div>
        </Form>
        <div className="signup-sec">
          <p>If you already have an account <Link to="/login">Login</Link></p>
        </div>

      </div>
    </>
  )
  return (
    <AppLayout hideBanner={true}>
      <Container className=" mt-8 mb-8" >
        <Row className="justify-content-md-center" >
          <Col md={6} sm={12} className="justify-center">{content}</Col>
        </Row>
      </Container>
    </AppLayout >
  )
}
