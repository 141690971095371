import { SET_BREADCRUMB } from './types';

const defaultState = {
    data: {
        breadcrumb: '',
    }
};

export default function breadcrumb(state = defaultState, action) {
    switch (action.type) {
      case SET_BREADCRUMB:
            return { ...state, data: action.payload };
        default:
            return state;
    }
}
