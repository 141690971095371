import React from 'react';

// import MTags from '@peuconomia/react-meta-tags';

function MetaTags(props) {
    return (
        <>
            <title>{props.title} - Rajvasant!</title>
            <meta name="description" content={props.description} />
            <meta name="keywords" content={props.keywords} />

            <meta property="og:title" content={props.title} />
            <meta property="og:image" content={props.image} />
        </>
    )
}

export default MetaTags;