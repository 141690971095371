import { CircularProgress } from '@material-ui/core';
import React from 'react';
// import useStyles from './styles';

const CommonButton = ({ children, width, height, classProp, rounded = false, style, loading, ...props }) => {
  // const classes = useStyles();

  return (
    <div>
      <button
        className="card-btn"
        disabled={loading}
        {...props}
      >
        {children}
      </button>
      {loading && <CircularProgress size={26} color="primary"  />}
    </div>
  )
}

export default CommonButton;
