import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  maxOrderMessage,
  stockFinishedMessage,
} from "../../../actions/constants";
import { CART_UPDATED } from "../../../reducers/types";
import {
  findProductInCart,
  formatCompanySettings,
  isNumeric,
  updateCartData,
} from "../../../utils";

const UpdateQuantity = ({ product, quantity, setQuantity }) => {
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  const cartData = cartState.data;
  // const [inputQty, setInputQty] = useState(quantity)

  const { companySettings } = useSelector((state) => state.meta);
  const settings = formatCompanySettings(companySettings);

  useEffect(() => {
    let quantity = findProductInCart(cartData, product);
    if (quantity?.qty) {
      setQuantity(quantity.qty);
    } else {
      setQuantity(1);
    }
  }, [setQuantity, cartData, product]);

  const updateCart = useCallback(
    (product, action) => {
      let cartProduct = findProductInCart(cartData, product);

      let newQty = quantity;

      if (action === "increase") {
        newQty = quantity + 1;
      }

      if (action === "decrease") {
        newQty = quantity - 1;
      }

      // atlease one qty must be there in cart.
      if (newQty === 0) {
        return;
      }

      // customer can not add more products than the available stock.
      if (newQty > product.available_stock) {
        stockFinishedMessage({ data: product });
        return;
      }

      // customer can not buy more products than the given value (it is a law).
      if (newQty > settings.maximumAllowedQuantityPerOrder) {
        maxOrderMessage({
          data: product,
          maximumAllowedQuantityPerOrder:
            settings.maximumAllowedQuantityPerOrder,
        });
        return;
      }
      setQuantity(newQty);

      if (cartProduct) {
        const newCartData = updateCartData(cartData, product, newQty);
        dispatch({ type: CART_UPDATED, payload: newCartData });
      }
    },
    [cartData, quantity, settings.maximumAllowedQuantityPerOrder, setQuantity, dispatch]
  );


  const updateInputQty = useCallback((e) => {

    let cartProduct = findProductInCart(cartData, product);

    let newQty = e.target.value;

    if (newQty.length && !isNumeric(newQty)) {
      return
    } 

    if (newQty < product.available_stock){
      setQuantity(newQty);
    }

    if (newQty > product.available_stock) {
      stockFinishedMessage({ data: product });
      return;
    }

    if (cartProduct) {
      const newCartData = updateCartData(cartData, product, newQty);
      dispatch({ type: CART_UPDATED, payload: newCartData });
    }
  }, [cartData, dispatch, product, setQuantity])

  return (
    <div className="flex justify-center items-center">
      <FontAwesomeIcon
        onClick={() => updateCart(product, "decrease")}
        className="text-xl text-app cursor-pointer mr-1"
        icon={faMinusCircle}
      />
      {/* <div className="font-bold">{quantity}</div> */}
      <input type="text" style={{width: 45, textAlign: 'center'}} value={quantity} onChange={updateInputQty} />
      <FontAwesomeIcon
        onClick={() => updateCart(product, "increase")}
        className="text-xl text-app cursor-pointer ml-1"
        icon={faPlusCircle}
      />
    </div>
  );
};

export default UpdateQuantity;
