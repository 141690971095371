import React from "react";
import PropTypes from "prop-types";
import UseResendOtp from "./UseResendOtp";

function ResendOTP({ renderTime, renderButton, style, className, ...props }) {
  const { remainingTime, handelResendClick } = UseResendOtp(props);


  return (
    <>
      <div
        data-testid="otp-resend-root"

      >
        {/* {renderTime ? (
        renderTime(remainingTime)
      ) : (
        <>
        You can resend the OTP in
        <span> {remainingTime} sec</span> 
        </>
      )} */}
      </div>

      <div>
        {/* {renderButton ? (
        renderButton({
          disabled: remainingTime !== 0,
          onClick: handelResendClick,
          remainingTime
        })
      ) : (
        <button disabled={remainingTime !== 0} onClick={handelResendClick}>
          Resend OTP
        </button>
      )} */}

        {remainingTime === 0 ? (
          <button onClick={handelResendClick}>
            Resend OTP
          </button>
        ) : (
          <>
            You can resend the OTP in
            <span> {remainingTime} secs</span>
          </>
        )}


      </div>
    </>

  );
}

ResendOTP.defaultProps = {
  maxTime: 30,
  timeInterval: 1000,
  style: {}
};

ResendOTP.propTypes = {
  onTimerComplete: PropTypes.func,
  onResendClick: PropTypes.func,
  renderTime: PropTypes.func,
  renderButton: PropTypes.func,
  maxTime: PropTypes.number,
  timeInterval: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string
};

export default ResendOTP;
