import React from "react";
import "./List.css";

export default function Filter() {
  return <div>
    <div className="filter-sec">
      <h3>Filter By Price</h3>
      <div className="amount-sec">
          <h6>$10</h6>
          <h6>$500</h6>
        </div>
      <div className="slide-container">
        <input type="range" name="" min="0" max="100"  className="slider" />
      </div>
    </div>
  </div>

}