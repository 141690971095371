import ChangePassword from '../../views/ChangePassword';
import Checkout from '../../views/Checkout';
import ContactUs from '../../views/ContactUs';
import ForgotPassword from '../../views/ForgotPassword';
import ImageGallery from '../../views/ImageGallery';
import ListPage from '../../views/ListPage';
import CategoryList from '../../views/ListPage/CategoryList';
import Login from '../../views/Login';
import Orders from '../../views/Orders';
import ProductDetail from '../../views/ProductDetail';
import Profile from '../../views/Profile';
import Shipping from '../../views/Shipping';
import SignUp from '../../views/Signup';
import VerifyEmail from '../../views/Signup/VerifyEmail';
import StaticPage from '../../views/StaticPage';
import VideoGallery from '../../views/VideoGallery';
import OrderDetail from "../../views/OrderDetail";
import Cart from '../../views/Cart';


const commonRoutes = [
  {
    path: "/category/:categoryId/:pageNumber/:name",
    element: <ListPage />,
  },
  { path: "/product/:productId/:name", element: <ProductDetail /> },
  { path: "/categories", element: <CategoryList /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/page/:pageName", element: <StaticPage /> },
  { path: "/login", element: <Login /> },
  { path: "/signup", element: <SignUp /> },
  { path: "/verify-email", element: <VerifyEmail /> },
  { path: "/forgot-password", element: <ForgotPassword /> },
  { path: "/image-gallery", element: <ImageGallery /> },
  { path: "/video-gallery", element: <VideoGallery /> },
  { path: "/my-profile", element: <Profile /> },
  { path: "/cart", element: <Cart /> },
];

const authProtectedRoutes = [
  { path: "/profile", element: <Profile /> },
  { path: "/change-password", element: <ChangePassword /> },
  { path: "/shipping", element: <Shipping/> },
  { path: "/checkout/:orderId", element: <Checkout/> },
  { path: "/orders", element: <Orders/> },
  { path: "/order/:id", element: <OrderDetail/> },
];

const publicRoutes = [
  // { path: '/signup', component: SignUp },
  // { path: '/forget-password', component: ForgetPassword }
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
