
export const getErrorMsg = (err) => {
    let msg = "Something went wrong! Please try again!";
    if (err.response && err.response.data && err.response.data[0] && err.response.data[0].message) {
        msg = err.response.data[0].message;
    } else if (err.response && err.response.data && err.response.data.message) {
        msg = err.response.data.message;
    }
    return msg;
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}


export const getUpdatedCartAfterAddProduct = (currData, product, qty) => {
    const productInCart = currData.filter(elem => elem.id === product.id);
    let newCart;
    if (productInCart.length) {
        const prodIndex = currData.findIndex(d => d.id === product.id);
        // const newQty = productInCart[0].qty + 1;
        newCart = [...currData.slice(0, prodIndex), { ...currData[prodIndex], qty }, ...currData.slice(prodIndex + 1)]
    } else {
        const prod = { ...product, qty }
        newCart = [...currData, prod];
    }
    return newCart;
}

export const getUpdatedCartAfterRemoveProduct = (currData, product, reducedQuantity = 1) => {
    let newCart;
    const idx = currData.findIndex(elem => elem.id === product.id);
    const newQty = currData[idx].qty - reducedQuantity;
    if (!newQty) {
        newCart = [...currData.slice(0, idx), ...currData.slice(idx + 1)]
    } else {
        newCart = [...currData.slice(0, idx), { ...currData[idx], qty: newQty }, ...currData.slice(idx + 1)]
    }
    return newCart;
}

// it is used to find the given product in to the cart data.
export const findProductInCart = (cartData, product) => {
    return cartData.find(cartProduct => cartProduct.id === product.id);
}


// this function is used to update the cart data such as add / update / remove
export const updateCartData = (cartData, product, qty) => {
    let newCart = [];

    const productInCart = findProductInCart(cartData, product);

    // if product is already added into the cart then we will update the qty
    if (productInCart) {

        newCart = cartData.map(cartProduct => {
            // If the product is matched in the loop then we will just return a new object by updating the qt.
            return (cartProduct.id === product.id) ? { ...cartProduct, qty } : cartProduct;
        });

    } else {
        // adding new product into the cart
        newCart = [...cartData, { ...product, qty }];
    }

    // removing the product where qty is 0
    newCart = newCart.filter(product => product.qty !== 0);

    return newCart;
}

export const getStatusColor = (status) => {
    if (status.toLowerCase().includes('success')) {
        return 'green';
    }
    if (status.toLowerCase().includes('reject') || status.toLowerCase().includes('fail')) {
        return 'red';
    }
    return 'orange'
}

export const validEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

// getting a single setting from the settings array.
export const getSetting = (key, settings) => {
    return settings.find(setting => setting.key === key);
}

export const textEllipsis = (text, length = 200) => {
    if (text && text.length > length) {
        return text.substring(0, length) + '...';
    }
    return text;
}

// this is used to return the amount after converting into the given currency.
export const getAmount = (amount, currency) => {
    return currency?.value ? (amount * currency.value).toFixed(currency.decimalPoints) : 0;
}


export const formatCompanySettings = (companySettings) => {

    const companyContactNumber = companySettings.find(elem => elem.key === "company_contact_number") ? companySettings.find(elem => elem.key === "company_contact_number").value : null;
    const companyWhatsAppNumber = companySettings.find(elem => elem.key === "company_whatsapp_number") ? companySettings.find(elem => elem.key === "company_whatsapp_number").value : null;
    const companyContactEmail = companySettings.find(elem => elem.key === "company_email_address") ? companySettings.find(elem => elem.key === "company_email_address").value : null;
    const homepageCategories = companySettings.find(elem => elem.key === "homepage_categories") ? companySettings.find(elem => elem.key === "homepage_categories").value : null;
    const copyrightMsg = companySettings.find(elem => elem.key === "footer_copyright_message") ? companySettings.find(elem => elem.key === "footer_copyright_message").value : null;
    const companyContactAddress = companySettings.find(elem => elem.key === "company_address") ? companySettings.find(elem => elem.key === "company_address").value : null;
    const topHeaderMsg = companySettings.find(elem => elem.key === "top_header_msg") ? companySettings.find(elem => elem.key === "top_header_msg").value : null;
    const maximumAllowedQuantityPerOrder = companySettings.find(elem => elem.key === "maximum_allowed_quantity_per_order") ? companySettings.find(elem => elem.key === "maximum_allowed_quantity_per_order").value : null;
    const supportedCountries = companySettings.find(elem => elem.key === "supported_countries") ? companySettings.find(elem => elem.key === "supported_countries").value : "";

    const socialUrls = {
        facebook: companySettings.find(elem => elem.key === "social_links_facebook") ? companySettings.find(elem => elem.key === "social_links_facebook").value : '#',
        twitter: companySettings.find(elem => elem.key === "social_links_twitter") ? companySettings.find(elem => elem.key === "social_links_twitter").value : '#',
        instagram: companySettings.find(elem => elem.key === "social_links_instagram") ? companySettings.find(elem => elem.key === "social_links_instagram").value : '#',
        linkedin: companySettings.find(elem => elem.key === "social_links_linkedin") ? companySettings.find(elem => elem.key === "social_links_linkedin").value : '#',
        playStore: companySettings.find(elem => elem.key === "social_links_play_store") ? companySettings.find(elem => elem.key === "social_links_play_store").value : '#',
    }

    return { supportedCountries, companyContactNumber, companyContactAddress, companyWhatsAppNumber, topHeaderMsg, companyContactEmail, copyrightMsg, socialUrls, homepageCategories, maximumAllowedQuantityPerOrder: parseInt(maximumAllowedQuantityPerOrder) };
}

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator = ",") => {
    let arr = [];
    if (value) {
        arr = value
            .split(seperator)
            .filter(Boolean)
            .map((v) => v.trim())
            .filter((v) => v !== "");
    }
    return arr;
};
