import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleRight,
  faChevronRight,
  faChevronDown,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import globalStyles from "../../styles/global.module.scss";
import "./List.css"
import { slug } from "../../actions/constants";
import {useNavigate} from 'react-router-dom';

function CategorySideList({
  formattedCategories,
  visibleCategories,
  toggleVisibleCategories,
  activeCategory,
}) {
  const navigate= useNavigate();

  return (
    <div className="category-sec">
      {formattedCategories.map((category) => {
        if (category.children.length) {
          const is_hidden = visibleCategories.indexOf(category.id) === -1;
          return (
            <div key={category.id} className="flex flex-col">
              <div
                onClick={toggleVisibleCategories.bind(this, category.id)}
                className=""
              >
                <div className="font-semibold">
                  <FontAwesomeIcon
                    className={`mr-1 ${globalStyles.appColor}`}
                    icon={faArrowCircleRight}
                  />
                  <span>{category.name}</span>
                </div>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={is_hidden ? faChevronRight : faChevronDown}
                />

              </div>
              <div className={is_hidden ? "hidden" : ""}>
                {category.children.map((subcategory) => {
                  return (
                    <div
                      key={subcategory.id}
                      className="flex items-center py-3 border-solid border-b pl-8"
                    >
                      <div
                        onClick={() => {
                          navigate(`/category/${subcategory.id}/${slug(subcategory.name)}`);
                        }}
                        className="font-semibold cursor-pointer"
                      >
                        <FontAwesomeIcon
                          className={`mr-1 ${globalStyles.appColor}`}
                          icon={faArrowCircleRight}
                        />
                        <span
                          className={`${activeCategory &&
                              subcategory.id === activeCategory.id
                              ? globalStyles.appColor
                              : ""
                            }`}
                        >
                          {subcategory.name}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        } else {
          return (

            <div
              onClick={() => {
                navigate(`/category/${category.id}/1/${slug(category.name)}`);
              }}
              key={category.id}
              className=" justify-between items-center category-sec-inner"
            >
              <div className={`category-text ${activeCategory &&
                              category.id === activeCategory.id
                              ? "activeCategoryText"
                              : ""
                            }`}>
                <FontAwesomeIcon
                  className={`category-icon `}
                  icon={faDotCircle}
                />
                <h4>{category.name}</h4>
              </div>
            </div>
          );
        }
      })}
    </div>

  );
}

export default CategorySideList;
