import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { showMessage } from "../../../actions/constants";

export default function AppRoute({ children }) {
  const userState = useSelector(state => state.user);
  if (!userState.data) {
    showMessage("You must be login to proceed.", "danger", 3000);
  }

  return userState.data ? children : <Navigate to="/login" />;
}
