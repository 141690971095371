export const formatTopMenuItems = (menu) => {
    const topMenuItems = menu.filter(elem => elem.category === 'top-menu');
    const result = [];
    topMenuItems.forEach(elem => {
        if (elem.parent_id === 0) {
            const children = topMenuItems
                .filter(item => item.parent_id === elem.id)
                .map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        url: item.url
                    }
                })
            if (children.length) {
                result.push({
                    id: elem.id,
                    name: elem.name,
                    url: elem.url,
                    children: children
                });
            } else {
                result.push({
                    id: elem.id,
                    name: elem.name,
                    url: elem.url
                });
            }

        }
    });
    return result;
}