import { authKey_local_storage_key } from "../helpers/common";
import { USER_LOGGED_IN, USER_LOGGED_OUT, USER_DETAILS_FETCHED } from "./types";

const defaultState = {
  data: null,
  userDetails: null,
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case USER_LOGGED_IN:
      localStorage.setItem("userInfo", JSON.stringify(action.payload));
      localStorage.setItem(authKey_local_storage_key, action.payload.token);
      return { ...state, data: action.payload };
    case USER_LOGGED_OUT:
      localStorage.removeItem("userInfo");
      return { ...state, data: null };
    case USER_DETAILS_FETCHED:
      return { ...state, userDetails: action.payload };
    default:
      return state;
  }
}
