import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AppLayout from '../../components/AppLayout';
import { Link, useNavigate } from 'react-router-dom';
import List from './List';
import AppLoader from '../../components/AppLoader';
import { ORDER_CHECKOUT_LOADED } from '../../reducers/types';
import { setBreadcrumb } from '../../actions/breadcrumb';


function Cart() {

    const cartState = useSelector(state => state.cart);
    const { brands } = useSelector(state => state.meta);
    const userState = useSelector(state => state.user);
    const { details } = useSelector(state => state.order);
    const navigate = useNavigate();

    const dispatch = useDispatch();


    useEffect(() => {
        window.scrollTo({
            top: 150,
            behavior: "smooth"
        });
    }, []);

    useEffect(() => {
        let breadcrumb = (
          <>
            <Link to="/">Home</Link>
            <Link className="active" to="#">
              / Cart
            </Link>
          </>
        );
        setBreadcrumb(dispatch, breadcrumb);
      }, [dispatch]);

    const handleSubmitClick = useCallback(() => {

        // if not logged in
        // if (!userState.data) {
        //     localStorage.setItem("redirect_after_login", "/shipping");
        //     showMessage("You must be login to proceed.", "warning", 3000);
        // }

        // clear last order information
        dispatch({ type: ORDER_CHECKOUT_LOADED, payload: {} });

        navigate('/shipping');

    }, [ navigate, dispatch]);


    let content = (
        <>
            {details.loading ? (
                <AppLoader />
            ) : null}
            <List userState={userState} handleSubmitClick={handleSubmitClick} brands={brands} cartState={cartState} />
        </>
    )

    return (
        <AppLayout hideSubscribeSection={true} hideBanner={true} hidePhoneDetail={true}>
            <div className={`myContainer my-5`}>
                {content}
            </div>
        </AppLayout>
    )
}

export default Cart;