import { ORDER_DETAILS_LOADING, ORDER_DETAILS_LOADED, ORDER_DETAILS_ERROR, ORDER_CHECKOUT_LOADING, ORDER_CHECKOUT_ERROR, ORDER_CHECKOUT_LOADED, CHECKOUT_DONE, COURIER_REGIONS_LOADING, COURIER_REGIONS_ERROR, COURIER_REGIONS_LOADED } from "./types";

const defaultState = {
    details: {
        loading: false, error: false, data: null
    },
    checkout: {
        loading: false, error: false, data: null
    },
    courierRegions: {
        loading: false, error: false, data: []
    }
};

export default function order(state = defaultState, action) {
    switch (action.type) {

        case ORDER_DETAILS_LOADING:
            return { ...state, details: { ...defaultState.details, loading: true } };
        case ORDER_DETAILS_ERROR:
            return { ...state, details: { ...defaultState.details, error: true } };
        case ORDER_DETAILS_LOADED:
            return { ...state, details: { ...defaultState.details, data: action.payload } };

        case COURIER_REGIONS_LOADING:
            return { ...state, courierRegions: { ...defaultState.courierRegions, loading: true } };
        case COURIER_REGIONS_ERROR:
            return { ...state, courierRegions: { ...defaultState.courierRegions, error: true } };
        case COURIER_REGIONS_LOADED:
            return { ...state, courierRegions: { ...defaultState.courierRegions, data: action.payload } };

        case ORDER_CHECKOUT_LOADING:
            return { ...state, checkout: { ...defaultState.checkout, loading: true } }
        case ORDER_CHECKOUT_ERROR:
            return { ...state, checkout: { ...defaultState.checkout, error: true } }
        case ORDER_CHECKOUT_LOADED:
            return { ...state, checkout: { ...defaultState.checkout, data: action.payload } }
        case CHECKOUT_DONE:
            return { ...defaultState }
        default:
            return state;
    }
}
