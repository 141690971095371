import React from 'react'
import { useSelector } from 'react-redux';
import { formatCompanySettings } from '../utils';

const WhatsAppIcon = () => {
  const { companySettings } = useSelector((state) => state.meta);
  const settings = formatCompanySettings(companySettings);
  return (
    <div className='sticky-whatsapp'>
      <a href={`https://wa.me/${settings?.companyWhatsAppNumber}`} target="_blank" rel="noreferrer">
        <img src="/images/whatsapp.png" width={50} alt="whatsapp-icon" />
      </a>
    </div>
  )
}

export default WhatsAppIcon