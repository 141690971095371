import React from "react";
import { Outlet } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import Header from "../../components/AppLayout/Header";
import { useSelector } from "react-redux";
import { formatCompanySettings } from "../../utils";
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import Footer from "../../components/AppLayout/Footer";
import CopyrightSection from "../../components/AppLayout/CopyrightSection";
import WhatsAppIcon from "../WhatsAppIcon";
function Layout() {
  const { menu, companySettings } = useSelector((state) => state.meta);
  const settings = formatCompanySettings(companySettings);

  const socialIcons = [
    {
      key: 1,
      component: faFacebookF,
      url: settings.socialUrls.facebook,
    },
    {
      key: 2,
      component: faTwitter,
      url: settings.socialUrls.twitter,
    },
    {
      key: 3,
      component: faInstagram,
      url: settings.socialUrls.instagram,
    },
    {
      key: 4,
      component: faLinkedinIn,
      url: settings.socialUrls.linkedin,
    },
  ];
  return (
    <>
      <ReactNotifications />
      <Header
        companyContactEmail={settings.companyContactEmail}
        companyContactNumber={settings.companyContactNumber}
        companyContactAddress={settings.companyContactAddress}
        companyWhatsAppNumber={settings.companyWhatsAppNumber}
        companySettings={companySettings}
        menu={menu}
        topHeaderMsg={settings.topHeaderMsg}
        socialIcons={socialIcons}
      />
      <ErrorBoundary>
        <Outlet />
        <WhatsAppIcon />
      </ErrorBoundary>

      <Footer
        menu={menu}
        socialUrls={settings.socialUrls}
        socialIcons={socialIcons}
        companyContactEmail={settings.companyContactEmail}
        companyContactNumber={settings.companyContactNumber}
        companyContactAddress={settings.companyContactAddress}
      />
      <CopyrightSection copyrightMsg={settings.copyrightMsg} />
    </>
  );
}

export default Layout;
