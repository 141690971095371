import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, compose } from "redux";
import reducers from "./reducers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import "./index.css";
import "./assets/main.css";
import Home from "./views/Home";
import NotFound from "./views/NotFound";
import { authProtectedRoutes, commonRoutes, publicRoutes } from "./store/routes";
import App from "./App";
import AppRoute from "./components/HOC/AppRoute";
import { USER_LOGGED_IN } from "./reducers/types";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancer());

if (localStorage.getItem('userInfo')) {
  store.dispatch({
    type: USER_LOGGED_IN,
    payload: JSON.parse(localStorage.getItem('userInfo')),
  });
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Routes>
          <Route path="/" element={<App />} exact>
            <Route index element={<Home />} />

            {commonRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}

            {publicRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))}

            {/* {authProtectedRoutes.map((route) => (
              <Route
                path={route.path}
                element={route.element}
                key={route.path}
              />
            ))} */}
            {authProtectedRoutes.map((route) => (
              <Route
                path={route.path}
                key={route.path}
                element={
                  <AppRoute>
                    {route.element}
                  </AppRoute>
                }
              />
            ))}


            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>, 
  document.getElementById("root")
);
