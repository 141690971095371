import React from "react";
import styles from "./index.module.scss";
import { image_server } from "../../actions/constants";
import Amount from "../../components/Amount";

function SideList({ cartState, courierCharges = "*Calculated at Next Step" }) {
  let total = 0;

  if (!(cartState && cartState.data)) {
    return null;
  }

  return (
    <div className="col-12 col-lg-5 mt-3 mt-lg-0">
      <div className="font-bold text-xl">Product Details</div>
      <div className="my-2">
        {cartState.data.map((d) => {
          total += d.qty * d.selling_price;
          return (
            <div key={d.id} className="flex items-center py-2 justify-between">
              <div className="flex items-center">
                <img
                  alt="product"
                  style={{ width: "50px", height: "50px" }}
                  src={image_server + `/products/${d.id}/${d.main_image}`}
                ></img>
                <div className="flex flex-col ml-2 pr-3 justify-between">
                  <div className={styles.productName}>{d.name}</div>
                  <div className="d-flex  align-items-center">
                    <Amount amount={d.selling_price} />
                    <div className="ml-4">Qty: {d.qty}</div>
                  </div>
                </div>
              </div>
              <div>
                <Amount amount={d.qty * d.selling_price} />
              </div>
            </div>
          );
        })}
      </div>
      <hr />
      <div className="flex items-center mt-4 justify-between">
        <div className="font-bold">Subtotal</div>
        <div className="font-bold">
          <Amount amount={total} />
        </div>
      </div>
      <div className="flex items-center my-2 justify-between">
        <div className="font-bold">Tax & Charges</div>
        <div className="font-bold">
          {isNaN(courierCharges) ? (
            courierCharges
          ) : (
            <span>
              <Amount amount={courierCharges} />
            </span>
          )}
        </div>
      </div>
      {!isNaN(courierCharges) ? (
        <>
          <hr />
          <div className="flex items-center mt-4 justify-between">
            <div className="font-bold">Total</div>
            <div className="font-bold">
              <Amount amount={total + courierCharges} />
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
}

export default SideList;
