import React, { useState } from 'react';

import '../style.css';

import { useEffect } from 'react';

import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useCallback } from 'react';

const Paypal = ({ createPayment, onPaymentSuccess, onPaymentFailed }) => {

    const [options, setOptions] = useState({});
    const [orderResponse, setOrderResponse] = useState({});

    const createOrder = useCallback(async (data, actions) => {
        if (orderResponse) {
            return actions.order.create({
                purchase_units: [
                    {
                        amount: {
                            value: orderResponse.order.total_amount * orderResponse.order.currency_value,
                        },
                    },
                ],
            });
        }
    }, [orderResponse]);

    const onApprove = async (data, actions) => {
        try{
            let response = await actions.order.capture();
            onPaymentSuccess(orderResponse.order.order_id, response);
        }catch(e){
            onPaymentFailed({
                'reason': e.message
            });
        }
    };

    const onError = (error) => {
        console.log(error);
        onPaymentFailed({
            'reason': 'Error occurred while processing payment. Please try again.'
        });
    };

    const createPaymentNow = useCallback(async () => {
        try {
            let orderResponse = await createPayment();
            if (orderResponse?.order) {
                setOptions({
                    'client-id': orderResponse?.settings?.api_details?.api_key,
                    currency: orderResponse?.order?.currency_code ?? 'INR',
                });
                setOrderResponse(orderResponse);
            } else {
                throw new Error('Something went wrong. Please try again.');
            }
        } catch (e) {
            onPaymentFailed({
                'reason': e.message
            });
        }
    }, [createPayment, onPaymentFailed]);

    useEffect(() => {
        createPaymentNow();
    }, [createPaymentNow]);

    if (!options?.currency) {
        return <img src={require('../images/loader.gif')} width={40} alt="loading" />
    }

    return (
        <PayPalScriptProvider options={options}>
            <PayPalButtons
                createOrder={(data, actions) => createOrder(data, actions)}
                onApprove={(data, actions) => onApprove(data, actions)}
                onError={(error) => onError(error)}
            />
        </PayPalScriptProvider>
    );

}

export default Paypal;
