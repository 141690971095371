import React, { useEffect, useCallback, useState } from "react";
import AppLayout from "../../components/AppLayout";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { fetchOrders, fetchOrderItems } from "../../actions/order";
import OrderCard from "./OrderCard";
import AppLoader from "../../components/AppLoader";
import { Link, useNavigate } from "react-router-dom";
import { updateUserCart } from "../../actions/users";
import { getMessages } from "../../actions/constants";

import { NotificationManager } from 'react-notifications';
import { setBreadcrumb } from "../../actions/breadcrumb";

function Orders() {
  const [resultState, setResultState] = useState({
    loading: true,
    error: false,
    data: [],
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loadingOrderItems, setLoadingOrderItems] = useState(false);

  const userState = useSelector((state) => state.user);

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / My Orders
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  const fetchUserOrders = useCallback(async () => {
    try {
      const res = await fetchOrders(userState.data.token);
      setResultState({ loading: false, error: false, data: res.data });
    } catch (err) {
      console.log(err);
      setResultState({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, [userState.data.token]);

  useEffect(() => {
    fetchUserOrders();
  }, [fetchUserOrders]);

  useEffect(() => {
    let messages = getMessages();
    messages.forEach(msg => NotificationManager[msg.type](msg.message));
  });

  const { loading, error, data } = resultState;

  let content = null;

  const handleReorder = useCallback(
    async (order) => {
      try {
        setLoadingOrderItems(true);
        const orderItemsResponse = await fetchOrderItems(
          userState.data.token,
          order.order_id
        );
        const updatedCartItems = orderItemsResponse.data.map((item) => {
          return {
            ...item.product,
            qty: parseInt(item.quantity),
          };
        });
        await updateUserCart(JSON.stringify(updatedCartItems), userState.data.token);
        setLoadingOrderItems(false);
        // dispatch({ type: CART_UPDATED, payload: updatedCartItems });
        navigate('/cart');
      } catch (err) {
        console.log(err);
        setLoadingOrderItems(false);
      }
    },
    [userState.data.token, navigate]
  );

  if (loading) {
    content = (
      <div className="flex h-64 justify-center items-center w-full">
        <Spinner animation="grow" variant="app" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  } else if (error) {
    content = (
      <div className="flex h-64 justify-center items-center w-full">
        <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
        <SimpleButton onClick={fetchUserOrders} title="Please try again" />
      </div>
    );
  } else if (!data.length) {
    content = (
      <div className="flex h-64 justify-center items-center w-full">
        No Orders Found!
      </div>
    );
  } else {
    let orders = [...data];
    orders.reverse();
    content = (
      <div className={`myContainer pb-5`}>
        <div className="font-bold text-3xl mt-4">My Orders</div>
        <div className="flex flex-wrap justify-around">
          {orders
            .map((d) => {
              return (
                <OrderCard handleReorder={handleReorder} key={d.id} order={d} />
              );
            })}
        </div>
      </div>
    );
  }

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {loadingOrderItems ? <AppLoader /> : null}
      {content}
    </AppLayout>
  );
}

export default Orders;
