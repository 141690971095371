import React, { useCallback, useEffect, useState } from "react";
import {
  InputGroup,
  FormControl,
  Spinner,
  Navbar,
  Nav,
  NavDropdown,
} from "react-bootstrap";
import styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatTopMenuItems } from "../utils";
import { Link, useNavigate } from "react-router-dom";
import { slug } from "../../../../actions/constants";
import {
  fetchProductCategories,
  getSearchedProducts,
} from "../../../../actions";
import "./custStyle.css";
import { useDispatch, useSelector } from "react-redux";
import Amount from "../../../Amount";
function NavSection({ menu }) {
  const [searchVal, setSearchVal] = useState("");
  const [productData, setProductData] = useState({
    loading: false,
    error: false,
    data: [],
  });
  const headerSearchRef = React.useRef();
  const headerMobileSearchRef = React.useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuItems = formatTopMenuItems(menu);
  const [hideSearchContent, setSearchHideContent] = useState(false);
  const handleProductItemClick = useCallback(
    (id, name) => {
      setSearchVal("");
      navigate(`/product/${id}/` + slug(name));
    },
    [navigate]
  );

  const handleSearchChange = useCallback(async (e) => {
    setSearchVal(e.target.value);
    setSearchHideContent(true);
    try {
      if (e.target.value.trim().length) {
        setProductData({
          loading: true,
          error: false,
          data: [],
        });
        const res = await getSearchedProducts(e.target.value.trim());
        setProductData({
          loading: false,
          error: false,
          data: res.data,
        });
      }
    } catch (err) {
      console.log(err);
      setProductData({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, []);
  const loadCategories = useCallback(() => {
    fetchProductCategories(dispatch);
  }, [dispatch]);

  const categoriesInfo = useSelector((state) => state.categories);
  const categories = categoriesInfo.data;

  useEffect(() => {
    if (!categories.length) {
      loadCategories();
    }
  }, [loadCategories, categories.length]);

  let searchContent = null;
  if (searchVal) {
    if (productData.loading) {
      searchContent = (
        <div className="flex p-2">
          <Spinner animation="grow" variant="app" role="status"></Spinner>
        </div>
      );
    } else if (productData.error) {
      searchContent = <div className="flex p-2">Something went wrong</div>;
    } else if (productData.data.length) {
      searchContent = (
        <div
          style={{ maxHeight: "10rem" }}
          className="flex overflow-scroll flex-col shadow-md p-2 overflow-x-hidden"
        >
          {productData.data.map((d) => {
            return (
              <div
                onClick={handleProductItemClick.bind(this, d.id, d.name)}
                className={`flex justify-between cursor-pointer p-2 ${styles.productItem}`}
                key={d.id}
              >
                <div className="item-name">{d.name}</div>
                <div className="text-app">
                  <Amount amount={d.selling_price} />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      searchContent = <div className="flex p-2 ">No Products Found!</div>;
    }
  }

  useEffect(() => {
    const handler = (event) => {
      if (
        !headerSearchRef?.current?.contains(event.target) &&
        !headerMobileSearchRef?.current?.contains(event.target)
      ) {
        setProductData({
          loading: false,
          error: false,
          data: [],
        });
        setSearchHideContent(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const renderItems = () => {
    return menuItems.map((elem) => {
      if (elem.children) {
        return (
          <NavDropdown key={elem.id} title={elem.name}>
            {elem.children.map((item) => {
              return (
                <NavDropdown.Item key={item.id} href={item.url}>
                  <b>{item.name}</b>
                </NavDropdown.Item>
              );
            })}
          </NavDropdown>
        );
      } else {
        return (
          <Nav.Link key={elem.id} as={Link} to={elem.url}>
            {elem.name}
          </Nav.Link>
        );
      }
    });
  };

  return (
    <div>
      <div className="navigation-agileits">
        <div className={`menus ${styles.myContainer}`}>
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <InputGroup className={`mobileForm ${styles.inputGroup}`}>
              <FormControl
                onChange={handleSearchChange}
                value={searchVal}
                className={styles.searchBar}
                placeholder="Search"
                aria-describedby="basic-addon1"
              />
              <div className={styles.searchicon}>
                {searchVal?.length > 0 ? (
                  <FontAwesomeIcon
                    onClick={() => setSearchVal("")}
                    icon={faTimes}
                  />
                ) : (
                  <FontAwesomeIcon icon={faSearch} />
                )}
              </div>
            </InputGroup>
            {hideSearchContent && (
              <div ref={headerMobileSearchRef} className="mobileSearchContent">
                {searchContent}
              </div>
            )}
            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`myNavbar ${styles.navbar}`}
            >
              <Nav className={`navbarItem ${styles.navbarItem}`}>
                {renderItems()}
              </Nav>
              <div className="ml-auto">
                <InputGroup className={`form ${styles.inputGroup}`}>
                  <FormControl
                    onChange={handleSearchChange}
                    value={searchVal}
                    className={styles.searchBar}
                    placeholder="Search"
                    aria-describedby="basic-addon1"
                  />
                  <div className={styles.searchicon}>
                    {searchVal?.length > 0 ? (
                      <FontAwesomeIcon
                        onClick={() => setSearchVal("")}
                        icon={faTimes}
                      />
                    ) : (
                      <FontAwesomeIcon icon={faSearch} />
                    )}
                  </div>
                </InputGroup>
                {hideSearchContent && (
                  <div ref={headerSearchRef} className="searchContent">
                    {searchContent}
                  </div>
                )}
              </div>
              {/* </Form> */}
            </Navbar.Collapse>
          </Navbar>
        </div>
      </div>
    </div>
  );
}

export default NavSection;
