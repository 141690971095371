import React, { useCallback, useEffect } from "react";
import ProductsContainer from "../../components/ProductsContainer";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductDetail } from "../../actions";
import { Spinner } from "react-bootstrap";
import SimpleButton from "../../components/Buttons/SimpleButton";
import Detail from "./Detail";
import AppLayout from "../../components/AppLayout";
import MetaTags from "../../components/MetaTags";

import { image_server } from "../../actions/constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import SimilarProducts from "../../components/SimilarProducts";
import { setBreadcrumb } from "../../actions/breadcrumb";

function ProductDetail() {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const productDetail = useSelector((state) => state.productDetail);
  const navigate = useNavigate();
  const loading = productDetail.loading;
  const error = productDetail.error;
  const activeProduct = productDetail.data;
  const categories = useSelector((state) => state.categories.data);
  const findActiveCate = categories?.find(
    (cate) => cate.id === parseInt(activeProduct[0]?.category_id)
  )?.name;

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link to="/Categories">/ Categories</Link>
        {findActiveCate ? (
          <Link
            to={`/category/${activeProduct[0]?.category_id}/1/${activeProduct[0]?.name}`}
          >
            / {findActiveCate}
          </Link>
        ) : null}
        <Link className="active" to="#">/ {activeProduct[0]?.name}</Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch, activeProduct, findActiveCate]);

  const loadProductDetail = useCallback(() => {
    fetchProductDetail(dispatch, productId);
  }, [dispatch, productId]);

  useEffect(() => {
    loadProductDetail();
  }, [loadProductDetail]);

  let content = <Detail product={activeProduct} />;

  if (loading) {
    content = (
      <div className="col-lg-9 d-lg-block pr-2 px-0">
        <Spinner animation="grow" variant="app" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    content = (
      <div className="flex h-64 justify-center items-center w-full">
        <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
        <SimpleButton onClick={loadProductDetail} title="Please try again" />
      </div>
    );
  }

  // if (!activeProduct.length) {
  //     content = (
  //         <div className="flex h-20 justify-center items-center w-full">
  //             <div className="font-bold mr-2">No product found.</div>
  //         </div>
  //     )
  // }

  return (
    <AppLayout hideBanner={true} hidePhoneDetail={true}>
      <ProductsContainer
        navigate={navigate}
        activeCategoryId={
          activeProduct.length ? activeProduct[0].category_id : null
        }
      >
        {content}
      </ProductsContainer>

      <SimilarProducts productId={productId} />

      {activeProduct && activeProduct[0] ? (
        <MetaTags
          title={activeProduct[0].name}
          description={activeProduct[0].highlightss}
          keywords={activeProduct[0].search_tags}
          image={
            image_server + "/products/120x120-" + activeProduct[0].main_image
          }
        ></MetaTags>
      ) : null}
    </AppLayout>
  );
}

export default ProductDetail;
