import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductDetail } from '../../actions';
import Detail from './Detail';
import AppLayout from '../../components/AppLayout';

import { useNavigate } from "react-router-dom";

import { fetchOrderItems } from "../../actions/order";

import AppLoader from "../../components/AppLoader";

import globalStyles from "../../styles/global.module.scss";

import { fetchOrderDetails } from "../../actions/order";

import { updateUserCart } from "../../actions/users";


function ProductDetail(props) {

  const [orderResultState, setOrderResultState] = useState({
    loading: true,
    error: false,
    data: [],
  });

  const [loadingOrderItems, setLoadingOrderItems] = useState(false);

  const [orderItemsResultState, setOrderItemsResultState] = useState({
    orderItems: [],
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userState = useSelector(state => state.user);


  const fetchDetails = useCallback(async () => {
    try {
      const res = await fetchOrderDetails(userState.data.token, props.match.params.id);
      setOrderResultState({ loading: false, error: false, data: res.data });

      const resItems = await fetchOrderItems(userState.data.token, props.match.params.id);

      const items = resItems.data.map((item) => {
        return {
          ...item.product,
          qty: parseInt(item.quantity),
        };
      });

      setOrderItemsResultState({ orderItems: items });

    } catch (err) {
      console.log(err);
      setOrderResultState({
        loading: false,
        error: true,
        data: [],
      });
    }
  }, [userState.data.token, props.match.params.id]);



  const handleReorder = useCallback(
    async (order) => {
      try {
        setLoadingOrderItems(true);
        const orderItemsResponse = await fetchOrderItems(
          userState.data.token,
          order.order_id
        );
        const updatedCartItems = orderItemsResponse.data.map((item) => {
          return {
            ...item.product,
            qty: parseInt(item.quantity),
          };
        });
        await updateUserCart(JSON.stringify(updatedCartItems), userState.data.token);
        setLoadingOrderItems(false);
        // dispatch({ type: CART_UPDATED, payload: updatedCartItems });
        navigate('/cart');
      } catch (err) {
        console.log(err);
        setLoadingOrderItems(false);
      }
    },
    [userState.data.token, navigate]
  );

  const loadProductDetail = useCallback(() => {
    fetchProductDetail(dispatch, props.match.params.id);
  }, [dispatch, props.match.params.id]);

  useEffect(() => {
    loadProductDetail();
  }, [loadProductDetail]);

  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  const { loading, data } = orderResultState;

  const { orderItems } = orderItemsResultState;

  var content = (
    <div className={`p-8 ${globalStyles.appContainer}`}>
      <div className="font-bold text-3xl">My Orders</div>
      <div className="flex flex-wrap justify-around">
        <Detail userState={userState} data={data} orderItems={{ data: orderItems }} handleReorder={handleReorder} />
      </div>
    </div>
  );

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {loading || loadingOrderItems ? <AppLoader /> : null}
      {content}
    </AppLayout>
  )
}

export default ProductDetail;