import React, { useState } from "react";
import SideList from "../Cart/SideList";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import PaymentButton from "./PaymentGateways/PaymentButton";
import PaymentGatewayList from "./PaymentGateways/PaymentGatewayList";
import { cartData_local_storage_key } from "../../helpers/common";

function CheckoutSummary({ shippingData }) {
  const cartState = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const handleBackOperation = () => {
    navigate("/cart");
  };
  const [currentGateway, setCurrentGateway] = useState({});

  return (
    <>
      <div className="row">
        <div className="col-12 col-lg-7  border-solid border-black border-r-2">
          <div className="font-bold text-xl">Checkout Details</div>
          <div className="mt-3">
            <div className="border border-transparent border-solid p-4 bg-gray-200 rounded">
              <div className="py-2">
                <span className="font-bold">Email:</span>{" "}
                {shippingData.customer.email}
              </div>
              <div className="py-2">
                <span className="font-bold">Name:</span>{" "}
                {shippingData.customer.name}
              </div>
              <div className="py-2">
                <span className="font-bold">Address:</span>{" "}
                {shippingData.customer.address}
              </div>
              <div className="py-2">
                <span className="font-bold">Mobile Number:</span>{" "}
                {shippingData.customer.mobile_number}
              </div>
              {shippingData.customer.city ? (
                <div className="py-2">
                  <span className="font-bold">City:</span>{" "}
                  {shippingData.customer.city}
                </div>
              ) : null}
              {shippingData.customer.country ? (
                <div className="py-2">
                  <span className="font-bold">Country:</span>{" "}
                  shippingData.customer.country
                </div>
              ) : null}
              <div className="py-2">
                <span className="font-bold">Pin Code:</span>{" "}
                {shippingData.customer.pin_code}
              </div>
              {shippingData.customer.additional_mobile_number ? (
                <div className="py-2">
                  <span className="font-bold">Alternate Mobile Number:</span>{" "}
                  {shippingData.customer.additional_mobile_number}
                </div>
              ) : null}
            </div>
          </div>

          <div>
            <PaymentGatewayList
              currentGateway={currentGateway}
              setCurrentGateway={setCurrentGateway}
            />
          </div>

          <div className="check-btn2">
            <PaymentButton
              currentGateway={currentGateway}
              cartStorageKey={cartData_local_storage_key}
            ></PaymentButton>

            <SecondaryButton
              className="mr-2 mb-3 mt-3 w-full back-btn"
              title="BACK"
              onClick={handleBackOperation}
            />
            {/* <div className="orImageContainer">
              <img src={OrImage} alt="or" />
            </div> */}
          </div>
        </div>

        <SideList
          cartState={cartState}
          courierCharges={shippingData.order.delivery_charges}
        />
      </div>
    </>
  );
}

export default CheckoutSummary;
