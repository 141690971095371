import axios from "axios";
import ajax from "./ajax";
import { server_url } from "./constants";
import {
  CATEGORIES_LOADING,
  CATEGORIES_LOADED,
  CATEGORIES_ERROR,
  PRODUCTS_LOADING,
  PRODUCTS_LOADED,
  PRODUCTS_ERROR,
  FEATURED_PRODUCTS_LOADED,
  FEATURED_PRODUCTS_ERROR,
  FEATURED_PRODUCTS_LOADING,
  PRODUCT_DETAIL_LOADING,
  PRODUCT_DETAIL_LOADED,
  PRODUCT_DETAIL_ERROR,
  META_LOADED,
  META_LOADING,
  USER_DETAILS_FETCHED,
  META_ERROR,
  USER_LOGGED_OUT,
} from "../reducers/types";
import { fetchUserDetails } from "./users";


export const fetchMenu = () => {
  return axios.get(`${server_url}/menus?per-page=1000&page=1`);
};

export const fetchSettings = () => {
  return axios.get(
    `${server_url}/settings?SettingsSearch[module_name]=frontend`
  );
};

export const fetchBrands = () => {
  return axios.get(`${server_url}/brands`);
};

export const fetchMetaData = async (dispatch, userState) => {
  try {
    dispatch({ type: META_LOADING });
    let promisesArray = [
      fetchMenu(),
      fetchSettings(),
      fetchBrands(),
      fetchAllBannerContent(),
    ];
    if (userState.data) {
      promisesArray = [
        ...promisesArray,
        fetchUserDetails(userState.data.token),
      ];
    }
    const apiResponses = await Promise.all(promisesArray);
    dispatch({
      type: META_LOADED,
      menu: apiResponses[0].data,
      companySettings: apiResponses[1].data,
      brands: apiResponses[2].data,
      banners: apiResponses[3].data,
    });
    if (userState.data) {
      const userDetails = { ...apiResponses[3].data };
      delete userDetails.cart_data;
      dispatch({ type: USER_DETAILS_FETCHED, payload: userDetails });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: META_ERROR,
    });
    localStorage.clear();
    dispatch({ type: USER_LOGGED_OUT });
    // window.location.href = "/login";
  }
};

export const fetchBannerContent = () => {
  return axios.get(
    `${server_url}/banners?BannersSearch[category]=Homepage Banners`
  );
};

export const fetchInnerBannerContent = () => {
  return axios.get(
    `${server_url}/banners?BannersSearch[category]=Inner Banners`
  );
};

export const fetchAllBannerContent = () => {
  return axios.get(
    `${server_url}/banners?per-page=1000&page=1&sort=display_order`
  );
};

export const fetchPageContent = (pageName) => {
  return axios.get(`${server_url}/pages?PageSearch[url_slug]=${pageName}`);
};

// export const fetchFeaturedProducts = async (dispatch) => {
//   try {
//     dispatch({ type: PACKAGES_LOADING });
//     const response = await axios.get(
//       `${server_url}/packages?PackagesSearch[category]=Wellness`
//     );
//     dispatch({ type: PACKAGES_LOADED, payload: response.data });
//   } catch (err) {
//     dispatch({ type: PACKAGES_ERROR });
//   }
// };


export const fetchFeaturedProducts = async (dispatch) => {
  try {
    dispatch({ type: FEATURED_PRODUCTS_LOADING });
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[product_labels]=Featured`
    );
    dispatch({ type: FEATURED_PRODUCTS_LOADED, payload: response.data });
  } catch (err) {
    dispatch({ type: FEATURED_PRODUCTS_ERROR });
  }
};

export const fetchProductCategories = async (dispatch) => {
  try {
    dispatch({ type: CATEGORIES_LOADING });
    const response = await axios.get(
      `${server_url}/categories?per-page=1000&page=1&sort=display_order`
    );
    dispatch({ type: CATEGORIES_LOADED, payload: response.data });
  } catch (err) {
    dispatch({ type: CATEGORIES_ERROR });
  }
};

export const fetchProducts = async (dispatch, categoryId, page = 1) => {
  try {
    dispatch({ type: PRODUCTS_LOADING });
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[category_id]=${categoryId}&per-page=20&page=${page}`
    );
    dispatch({ type: PRODUCTS_LOADED, payload: response.data });
  } catch (err) {
    dispatch({ type: PRODUCTS_ERROR });
  }
};

export const fetchProductDetail = async (dispatch, productId) => {
  try {
    dispatch({ type: PRODUCT_DETAIL_LOADING });
    const response = await axios.get(
      `${server_url}/products?ProductsSearch[id]=${productId}`
    );
    dispatch({ type: PRODUCT_DETAIL_LOADED, payload: response.data });
  } catch (err) {
    dispatch({ type: PRODUCT_DETAIL_ERROR });
  }
};

export const getSingleProduct = async (id) => {
  return axios.get(`${server_url}/products/` + id);
};

export const getPinCodeDetails = (pincode) => {
  return axios.get(`https://api.postalpincode.in/pincode/${pincode}`);
};

export const getSearchedProducts = (searchVal) => {
  return axios.get(
    `${server_url}/products?ProductsSearch[keyword]=${searchVal}`
  );
};

export const subscribeToNewsLetter = (email) => {
  return axios.post(`${server_url}/newsletters`, {
    email,
  });
};

export function verifyPayment(data) {
  return ajax("/orders/verify_payment", { method: "POST", data });
}


export function getSimilarProducts(productId) {
  return ajax(`/products?ProductsSearch[similar_of]=${productId}`);
}