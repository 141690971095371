/* eslint-disable linebreak-style */
import React, { useEffect } from "react";
import Layout from "./views/Layout";

const PATHS = [];
function App() {
  useEffect(() => {
    PATHS.forEach((path) => {
      const script = document.createElement("script");
      script.src = path;
      script.async = true;

      document.body.appendChild(script);
    });
  }, []);

  return <Layout />;
}

export default App;
