import React, { useCallback } from "react";
import SimpleButton from "../../../components/Buttons/SimpleButton";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { slug, image_server } from "../../../actions/constants";
import { textEllipsis } from "../../../utils";
import Amount from "../../../components/Amount";

function FeaturedProducts({
  featuredProducts,
  homePageFeaturedProductsMoreButtonUrl,
}) {
  const navigate = useNavigate();
  const moreProduct = useCallback(() => {
    navigate(`${homePageFeaturedProductsMoreButtonUrl}`);
  }, [navigate, homePageFeaturedProductsMoreButtonUrl]);

  return (
    <div>
      <div className="myContainer">
        <div className="product-sec featured-prod-sec">
          <h3>FEATURED PRODUCTS</h3>

          <div className="row pt-2">
            {featuredProducts.map((product) => {
              const img_url =
                image_server +
                `/products/${product.id}/600x600-${product.main_image}`;
              return (
                <div
                  onClick={() =>
                    navigate(`/product/${product.id}/${slug(product.name)}`)
                  }
                  key={product.id}
                  className={`cursor-pointer col-md-4 col-sm-6`}
                >
                  <div key={product.id}>
                    <a href={product.url}>
                      <div className={`product-box `}>
                        <div className="product-img">
                          <img src={`${img_url}`} alt="" />
                        </div>

                        <div className="product-btn-block">
                          <div className="product-btn-content">
                            <div className="product-name">
                              <h5>{textEllipsis(product.name, 55)}</h5>
                            </div>
                            <span className="selling-price">
                              {product.selling_price !== product.mrp && (
                                <del className="mrp-price">
                                  <Amount amount={product.mrp} />
                                </del>
                              )}
                              <Amount amount={product.selling_price} />
                            </span>
                          </div>
                          <div className={`view-detail`}>VIEW DETAILS</div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              );
            })}
          </div>

          <SimpleButton
            className={`more-btn`}
            onClick={() => moreProduct()}
            title="more"
          />
        </div>
      </div>
    </div>
  );
}

export default FeaturedProducts;
