import React from 'react';

function ContactSection({ companyContactNumber, companySettings }) {

    if (!companyContactNumber) return null;

    return (
        // <div className={`${styles.container} shadow flex py-2 items-center`}>
        <div>
            {/* <div className="flex p-0 col-12 col-md-9 justify-start items-center">
                <div className={`${styles.phoneIconContainer} mr-2 rounded-full h-12 w-12 flex items-center justify-center`}>
                    <a href={`tel:${companyContactNumber}`}>
                        <FontAwesomeIcon style={{ color: 'white' }} icon={faPhoneAlt} />
                    </a>
                </div>
                <div>
                    <div>{contactMsg}</div>
                    <a href={`tel:${companyContactNumber}`} className={`d-none d-md-flex ${styles.phoneNo}`}>{companyContactNumber}</a>
                </div>

            </div> */}
            {/* <div className="d-none p-0 d-md-flex flex col-md-3 justify-end">
                <AnchorBtn
                    url={`tel:${companyContactNumber}`}
                    className="mr-2"
                    title='CALL NOW'
                />
            </div> */}
        </div>
    )
}

export default ContactSection;