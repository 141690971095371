import React, { useEffect } from "react";
import "./List.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card } from "react-bootstrap";
import { image_server, slug } from "../../actions/constants";
import AppLayout from "../../components/AppLayout";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { setBreadcrumb } from "../../actions/breadcrumb";

function CategoryList() {
  const ProductCategories = useSelector((state) => state.categories.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">/ Categories</Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  // console.log('ProductCategories::', ProductCategories)

  const productCategory = (
    <div className="row">
      {ProductCategories.map((category) => {
        return category.parent_id === 1 ? (
          <div
            key={`category-${category.id}`}
            className="col-md-4 col-sm-6 my-col"
          >
            <Card
              onClick={() =>
                navigate(`/category/${category.id}/1/${slug(category.name)}`)
              }
              className={`main-card cursor-pointer`}
              //style={{ width: "15rem",height: "23rem" }}
            >
              {/* <Card.Img
                style={{ height: "250px", width: "100%" }}
                className="product-img categories-img"
                variant="top"
                src={image_server + `/categories/316x250-${category.image}`}
              /> */}
              <LazyLoadImage
                alt={category.name}
                style={{ height: "250px", width: "100%" }}
                effect="blur"
                className="product-img"
                src={image_server + `/categories/316x250-${category.image}`}
              />
              <Card.Body className="card-body">
                <div className="my-card-title">
                  {category.name.toUpperCase()}
                </div>
                <div className="my-card-title">
                  {category.total_products} Products
                </div>
              </Card.Body>
              {/* <SimpleButton
          className={`card-btn`}
          onClick={() => addProductToCart(category)}
          title="ADD TO CART"
        /> */}
            </Card>
          </div>
        ) : null;
      })}
    </div>
  );

  return (
    <AppLayout
      hideBanner={true}
      hidePhoneDetail={true}
      hideSubscribeSection={true}
    >
      <div className={` myContainer`}>
        <div className="categories-sec">
          <h3>Categories</h3>
          {productCategory}
        </div>
      </div>
    </AppLayout>
  );
}

export default CategoryList;
