import { PRODUCT_DETAIL_LOADING, PRODUCT_DETAIL_LOADED, PRODUCT_DETAIL_ERROR } from './types';

const defaultState = {
    data: [],
    loading: true,
    error: false
};

export default function productDetail(state = defaultState, action) {
    switch (action.type) {
        case PRODUCT_DETAIL_LOADING:
            return { ...state, loading: true, error: false, data: [] };
        case PRODUCT_DETAIL_ERROR:
            return { ...state, error: true, loading: false, data: [] };
        case PRODUCT_DETAIL_LOADED:
            return { ...state, error: false, loading: false, data: action.payload };
        default:
            return state;
    }
}