import React from 'react';
import "./Copyright.css";
import parse from 'html-react-parser';

function CopyrightSection({copyrightMsg}) {
    return (
        <div className={`copyright`}>
            {copyrightMsg && parse(copyrightMsg)}
            {/* CopyRight &#xa9; {new Date().getFullYear()} Rajvasant!. All Rights Reserved. */}
        </div>
    )
}

export default CopyrightSection;