import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { bannerSize, image_server } from "../../../actions/constants";
import { getContentByCategoryName } from "../../../helpers/common";
import "./styles.css";
// this library is called "lodash" and used to work with array and objects easily.
import _ from "lodash";

export default function OfferSec() {
  const banners = useSelector((state) => state.meta.banners);

  // getting all left offers
  const homepage_banners_left_array = getContentByCategoryName(
    banners,
    "Homepage Banners Left"
  );

  // to get a single random element from the given array.
  const homepage_banners_left = _.sample(homepage_banners_left_array) || {};

  // getting all right offers
  const homepage_banners_right_array = getContentByCategoryName(
    banners,
    "Homepage Banners Right"
  );

  // to get 2 random elements from the given array.
  const homepage_banners_right = _.sampleSize(homepage_banners_right_array, 2);

  return (
    <div className="myContainer">
      <div className="offer-sec">
          {/* <Row className="">
            <div className="mb-md-0 mb-4 col-md-6 offer-col ">
              <Link to={homepage_banners_left.url}>
                <img
                  className="img-fluid offer-img"
                  src={
                    image_server +
                    `/banners/${bannerSize}-${homepage_banners_left.image}`
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-6 offer-col">
              {homepage_banners_right.map((offerRight) => (
                <div key={`offers-right-${offerRight.id}`} className="imgBlock1">
                  <Link to={offerRight.url}>
                    <img
                      className="img-fluid offer-img"
                      src={
                        image_server +
                        `/banners/${bannerSize}-${offerRight.image}`
                      }
                      alt=""
                    />
                  </Link>
                </div>
              ))}
            </div>
          </Row> */}
          {/* <button onClick={() => localStorage.clear()}>clear </button> */}
          <div className="row">
            <div className="col-md-6 left-col">
            <Link to={homepage_banners_left.url}>
                <img
                  className="img-fluid offer-img"
                  src={
                    image_server +
                    `/banners/${bannerSize}-${homepage_banners_left.image}`
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="col-md-6">

            {homepage_banners_right.map((offerRight) => (
                <div key={`offers-right-${offerRight.id}`} className="imgBlock1">
                  <Link to={offerRight.url}>
                    <img
                      className="img-fluid offer-img"
                      src={
                        image_server +
                        `/banners/${bannerSize}-${offerRight.image}`
                      }
                      alt=""
                    />
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
