export const CATEGORIES_LOADING = 'CATEGORIES_LOADING';
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_LOADED = 'CATEGORIES_LOADED';
export const PACKAGES_LOADED = 'PACKAGES_LOADED';
export const PACKAGES_LOADING = 'PACKAGES_LOADING';
export const PACKAGES_ERROR = 'PACKAGES_ERROR';

export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_ERROR = 'PRODUCTS_ERROR';

export const COURIER_REGIONS_LOADED = 'COURIER_REGIONS_LOADED';
export const COURIER_REGIONS_LOADING = 'COURIER_REGIONS_LOADING';
export const COURIER_REGIONS_ERROR = 'COURIER_REGIONS_ERROR';

export const SET_BREADCRUMB = 'SET_BREADCRUMB';

export const CURRENT_CURRENCY_CHANGED = 'CURRENT_CURRENCY_CHANGED';
export const SUPPORTED_CURRENCIES_LOADED = 'SUPPORTED_CURRENCIES_LOADED';

export const FEATURED_PRODUCTS_LOADED = 'FEATURED_PRODUCTS_LOADED';
export const FEATURED_PRODUCTS_LOADING = 'FEATURED_PRODUCTS_LOADING';
export const FEATURED_PRODUCTS_ERROR = 'FEATURED_PRODUCTS_ERROR';

export const PRODUCT_DETAIL_LOADING = 'PRODUCT_DETAIL_LOADING';
export const PRODUCT_DETAIL_LOADED = 'PRODUCT_DETAIL_LOADED';
export const PRODUCT_DETAIL_ERROR = 'PRODUCT_DETAIL_ERROR';
export const META_LOADING = 'META_LOADING';
export const META_ERROR = 'META_ERROR';
export const META_LOADED = 'META_LOADED';
export const USER_LOGGED_IN = 'USER_LOGGED_IN'
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const USER_DETAILS_FETCHED = 'USER_DETAILS_FETCHED';
export const CART_UPDATED = 'CART_UPDATED';
export const LOAD_CART_DATA = 'LOAD_CART_DATA';
export const ORDER_DETAILS_LOADING = 'ORDER_DETAILS_LOADING';
export const ORDER_DETAILS_ERROR = 'ORDER_DETAILS_ERROR';
export const ORDER_DETAILS_LOADED = 'ORDER_DETAILS_LOADED';
export const ORDER_CHECKOUT_LOADING = 'ORDER_CHECKOUT_LOADING';
export const ORDER_CHECKOUT_ERROR = 'ORDER_CHECKOUT_ERROR';
export const ORDER_CHECKOUT_LOADED = 'ORDER_CHECKOUT_LOADED';

// ORDER ATTACHMENT
export const ORDER_ATTACHMENT_LOADING = 'ORDER_ATTACHMENT_LOADING';
export const ORDER_ATTACHMENT_LOADED = 'ORDER_ATTACHMENT_LOADED';
export const ORDER_ATTACHMENT_ERROR = 'ORDER_ATTACHMENT_ERROR';
//
export const CHECKOUT_DONE = 'CHECKOUT_DONE';
export const CLEAR_CART = 'CLEAR_CART';