import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppLayout from "../../components/AppLayout";
import AppLoader from "../../components/AppLoader";
import { Form } from "react-bootstrap";
import { validEmail, getErrorMsg, getSetting } from "../../utils";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { callContactUsApi } from "../../actions/users";
import MetaTags from "../../components/MetaTags";

import ReCAPTCHA from "react-google-recaptcha";

import { showMessage } from "../../actions/constants";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../actions/breadcrumb";

function ContactUs() {
  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }, []);

  const initialState = useMemo(() => {
    return {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
  }, []);

  const [isContactUsInitiated, setIsContactUsInitiated] = useState(false);

  const { companySettings } = useSelector((state) => state.meta);

  let google_recaptcha_site_key = getSetting(
    "google_recaptcha_site_key",
    companySettings
  );

  const [values, setValues] = useState(initialState);

  const recaptchaRef = React.createRef();

  const handleChange = useCallback((field, e) => {
    const { value } = e.target;
    setValues((currValues) => {
      return {
        ...currValues,
        [field]: value,
      };
    });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.name.trim().length) {
        showMessage("Please enter Name!", "warning");
        return false;
      }
      if (!values.email.trim().length || !validEmail(values.email)) {
        showMessage("Please enter a valid Email!", "warning");
        return false;
      }
      if (!values.subject.trim().length) {
        showMessage("Please enter Subject!", "warning");
        return false;
      }
      if (!values.message.trim().length) {
        showMessage("Please enter Message!", "warning");
        return false;
      }

      const captcha_value = recaptchaRef.current.getValue();
      if (!captcha_value) {
        showMessage("Please verify captcha!", "warning");
        return false;
      }

      recaptchaRef.current.reset();

      setIsContactUsInitiated(true);
      try {
        const { name, email, subject, message } = values;
        await callContactUsApi({
          name,
          email,
          subject,
          message,
          captcha_value,
        });
        setIsContactUsInitiated(false);
        setValues(initialState);
        showMessage(
          "Your query has been submitted successfully! We will contact you shortly!",
          "success"
        );
      } catch (err) {
        console.log(err);
        setIsContactUsInitiated(false);
        showMessage(getErrorMsg(err), "warning");
      }
    },
    [initialState, values, recaptchaRef]
  );

  const dispatch = useDispatch();

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Contact Us
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {isContactUsInitiated ? <AppLoader /> : null}
      <div className="myContainer">
        <div className="contact-sec text-center">
          <h3>Contact Us</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                value={values.name}
                onChange={handleChange.bind(this, "name")}
                className="mt-0"
                type="text"
                placeholder="Name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                value={values.email}
                onChange={handleChange.bind(this, "email")}
                className="mt-0"
                type="text"
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                value={values.subject}
                onChange={handleChange.bind(this, "subject")}
                className="mt-0"
                type="text"
                placeholder="Subject"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                as="textarea"
                value={values.message}
                onChange={handleChange.bind(this, "message")}
                className="mt-0"
                type="text"
                placeholder="Enter your message"
              />
            </Form.Group>

            <div className="form-captcha">
              {google_recaptcha_site_key && google_recaptcha_site_key.value ? (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={google_recaptcha_site_key.value}
                />
              ) : null}
            </div>
            <br />

            <SimpleButton title="Submit" className="form-submit" />
          </Form>
        </div>
      </div>

      <MetaTags
        title="Contact Us"
        description="You can reach us for any query or inquiry"
      ></MetaTags>
    </AppLayout>
  );
}

export default ContactUs;
