import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const AboutUsSec = ({ detailsAboutCompany }) => {
  return (
    <div className="about-sec">
      <div className="row">
        <div className="my-col col-lg-6 p-0 about-col-left">
          <Link to="/">
            <img src="/images/about-bg1.jpg" alt="" />
          </Link>
        </div>
        <div className="my-col col-lg-6 p-0 about-col-right">
          <Link to="/">
            <img src="/images/about-bg2.jpg" alt="" />
          </Link>
          <div className="about-text">
            <h3> ABOUT US </h3>
            <div className="about-para mt-2">
              <p className="para1">
                {detailsAboutCompany
                  ? parse(detailsAboutCompany)
                  : "Not Available"}
              </p>
            </div>
            <div className="btn-block">
              <Link to={"/page/about-us"}>more</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSec;
