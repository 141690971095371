import React, { useCallback } from 'react';
import { Table } from 'react-bootstrap';
import styles from './index.module.scss';
import SimpleButton from '../../components/Buttons/SimpleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { CART_UPDATED } from '../../reducers/types';
import { updateCartData, formatCompanySettings, isNumeric } from '../../utils';
import { image_server, stockFinishedMessage, maxOrderMessage, cartRemovedMessage } from '../../actions/constants';
import Amount from '../../components/Amount';


function List({ brands, cartState, handleSubmitClick }) {
    let total = 0;

    const dispatch = useDispatch();

    const cartData = cartState.data;

    const { companySettings } = useSelector(state => state.meta);
    const settings = formatCompanySettings(companySettings);

    // const addProductToCart = useCallback((data) => {
    //     const newCart = getUpdatedCartAfterAddProduct(cart.data, data);
    //     if (userState.data) {
    //         updateUserCart(JSON.stringify(newCart), userState.data.token);
    //     }
    //     dispatch({ type: CART_UPDATED, payload: newCart });
    // }, [dispatch, cart.data, userState.data]);


    // const removeProductFromCart = useCallback((data, reduced_quantity = 1) => {
    //     const newCart = getUpdatedCartAfterRemoveProduct(cart.data, data, reduced_quantity);
    //     updateUserCart(JSON.stringify(newCart), userState.data);
    //     dispatch({ type: CART_UPDATED, payload: newCart })
    // }, [dispatch, cart.data, userState.data]);

    const removeProductFromCart = useCallback((product) => {

        const newCartData = updateCartData(cartData, product, 0);


        dispatch({ type: CART_UPDATED, payload: newCartData });

        cartRemovedMessage({ data: product });

    }, [dispatch, cartData]);


    const updateCart = useCallback((product, action) => {

        let newQty = product.qty;

        if (action === 'increase') {
            newQty = product.qty + 1;
        }

        if (action === 'decrease') {
            newQty = product.qty - 1;
        }

        // atlease one qty must be there in cart.
        if (newQty === 0) {
            return;
        }

        // customer can not add more products than the available stock.
        if (newQty > product.available_stock) {
            stockFinishedMessage({ data: product });
            return;
        }

        // customer can not buy more products than the given value (it is a law).
        if (newQty > settings.maximumAllowedQuantityPerOrder) {
            maxOrderMessage({ data: product, maximumAllowedQuantityPerOrder: settings.maximumAllowedQuantityPerOrder });
            return;
        }

        const newCartData = updateCartData(cartData, product, newQty);
        dispatch({ type: CART_UPDATED, payload: newCartData });

    }, [dispatch, cartData, settings]);


    const updateInputQty = useCallback((e, product) => {

        let newQty = e.target.value;
    
        if (newQty.length && !isNumeric(newQty)) {
          return
        } 
    
        if (newQty > product.available_stock) {
          stockFinishedMessage({ data: product });
          return;
        }
    
          const newCartData = updateCartData(cartData, product, newQty);
          dispatch({ type: CART_UPDATED, payload: newCartData });
      }, [cartData, dispatch])

    return (
        <>
            <div className="flex justify-between pb-4 border-b-2 items-center">
                <div className="font-bold text-2xl">Shopping Cart</div>
                <div className="font-bold text-2xl">{cartState.data.length} items</div>
            </div>
            {cartState.data.length ? (
                <div className="mt-4">
                    <Table responsive borderless>
                        <thead>
                            <tr>
                                <th className={styles.tableHeader}>Product Details</th>
                                <th className={`text-center ${styles.tableHeader}`}>Quantity</th>
                                <th className={`text-center ${styles.tableHeader}`}>Price</th>
                                <th className={`text-right ${styles.tableHeader}`}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {cartState.data.map(d => {
                                const brand = brands.find(elem => parseInt(elem.id) === parseInt(d.brand_id));
                                total += d.qty * d.selling_price;
                                return (
                                    <tr key={d.id}>
                                        <td>
                                            <div className="flex justify-start">
                                                <div className="d-none d-lg-block">
                                                    <img alt="product" style={{ width: '100px', height: '100px' }} src={image_server + `/products/${d.id}/120x120-${d.main_image}`}></img>
                                                </div>
                                                <div className="px-lg-4 flex flex-col justify-between">
                                                    <div className="font-bold">{d.name}</div>
                                                    <div className="text-red-500">{brand ? brand.name : ''}</div>
                                                    <div onClick={() => removeProductFromCart(d, d.qty)} className="text-gray-500 cursor-pointer font-bold">Remove</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="text-center align-middle">
                                            <div className="flex justify-center items-center">
                                                <FontAwesomeIcon onClick={() => updateCart(d, 'decrease')} className="text-xl text-app cursor-pointer mr-2" icon={faMinusCircle} />
                                                {/* <div className="font-bold">{d.qty}</div> */}
                                                <input className="font-bold" type="text" style={{width: 45, textAlign: 'center'}} value={d.qty} onChange={(e) => updateInputQty(e, d)} />
                                                <FontAwesomeIcon onClick={() => updateCart(d, 'increase')} className="text-xl text-app cursor-pointer ml-2" icon={faPlusCircle} />
                                            </div>
                                        </td>
                                        <td className="text-center align-middle"><Amount amount={d.selling_price} /></td>
                                        <td className="text-right align-middle"><Amount amount={d.qty * d.selling_price} /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>

                    <div className="flex justify-end font-bold">
                        Sub-Total: &nbsp; <Amount amount={total} />
                    </div>
                    <div className="flex justify-end items-center mt-4">
                        <div className="text-sm mr-2 font-bold">*Shipping &amp; taxes calculated at checkout</div>
                        <SimpleButton onClick={handleSubmitClick} title="Proceed" />
                    </div>
                </div>
            ) : (
                <div className="mt-4 flex justify-center items-center h-64">Nothing is added to the cart!</div>
            )}
        </>
    )
}

export default List;