import React, { useEffect, useCallback, useState } from "react";
import parse from "html-react-parser";
import { fetchPageContent } from "../../actions";
import AppLayout from "../../components/AppLayout";

import MetaTags from "../../components/MetaTags";
import "./About.css";
import { Link, useParams } from "react-router-dom";
import AppLoader from "../../components/AppLoader";
import { setBreadcrumb } from "../../actions/breadcrumb";
import { useDispatch } from "react-redux";

function StaticPage(props) {
  const { pageName } = useParams();
  const [pageContent, setPageContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  
  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">/ {pageName?.replace(/-/g, " ")}</Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch, pageName]);


  const asyncCallsOnLoad = useCallback(async () => {
      setLoading(true)
    try {
      const response = await fetchPageContent(pageName);
      setPageContent(response.data[0]);
      setLoading(false);
      window.scrollTo({
        top: 300,
        behavior: "smooth",
      });
    } catch (err) {
      console.log(err.response);
      setLoading(false);
    }
  }, [pageName]);

  useEffect(() => {


    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);

  let pageJsx = null;

  if (pageContent) {
    pageJsx = (
      <div
        className={`flex flex-col justify-center items-start my-4 myContainer`}
      >
        {loading ? (
          <AppLoader/>
        ) : (
          <>
            <div className="text-2xl font-semibold aboutPage-heading">
              <h3>{pageContent.title}</h3>
            </div>
            <div className="mt-2">{parse(pageContent.content)}</div>:{" "}
          </>
        )}

        <MetaTags
          title={pageContent.title}
          description={pageContent.meta_description}
          keywords={pageContent.meta_keyword}
        ></MetaTags>
      </div>
    );
  }

  return <AppLayout hideBanner={true}>{pageJsx}</AppLayout>;
}

export default StaticPage;
