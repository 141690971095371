import React, { useEffect } from 'react';
import LogoSection from './LogoSection';
import SocialSection from './SocialSection';
import NavSection from './NavSection';
import { useDispatch } from 'react-redux';
import { LOAD_CART_DATA } from '../../../reducers/types';


function Header({ menu, companySettings, companyContactEmail, companyContactNumber, companyContactAddress, socialIcons,topHeaderMsg }) {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: LOAD_CART_DATA });
    }, [dispatch]);

    return (
        <>
            <SocialSection
                companyContactNumber={companyContactNumber}
                companyContactEmail={companyContactEmail}
                companyContactAddress={companyContactAddress}
                companySettings={companySettings}
                socialIcons={socialIcons}
                topHeaderMsg={topHeaderMsg}
            />
            <LogoSection companySettings={companySettings} />
            <NavSection menu={menu} companySettings={companySettings} />
        </>

    )
}

export default Header;