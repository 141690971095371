import React from 'react';
import AddressForm from './AddressForm';
import SideList from '../Cart/SideList';

function Address({ cartState, handleAddressSubmit, handleBackOperation }) {

    return (
        <div className="row">
            <div className="col-12 col-lg-7 border-solid border-black border-r-2">
                <div className="font-bold text-xl">Shipping Details</div>
                <div className="mt-3">
                    <AddressForm handleBackOperation={handleBackOperation} handleAddressSubmit={handleAddressSubmit} />
                </div>
            </div>
            <SideList cartState={cartState} />
        </div>
    )
}

export default Address;