import React, { useEffect, useState } from "react";
import { getSimilarProducts } from "../../actions";
import "./style.css";
import { Card } from "react-bootstrap";
import { slug, image_server } from "../../actions/constants";
import { useSelector } from "react-redux";
import AddCartButton from "../../components/AddCartButton";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";
import Amount from "../../components/Amount";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const SimilarProducts = ({ productId }) => {
  const [similarProducts, setSimilarProducts] = useState([]);
  const navigate = useNavigate();
  const cartData = useSelector((state) => state.cart.data);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && productId) {
      getSimilarProducts(productId)
        .then(function (response) {
          setSimilarProducts(response.data);
        })
        .catch(function (error) {
          console.error("error::", error);
        });
    }

    return () => {
      isCancelled = true;
    };
  }, [productId]);

  const responsive = {
    default: {
      breakpoint: { max: 4000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3,
    },
    smallTab: {
      breakpoint: { max: 766, min: 500 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 499, min: 0 },
      items: 1,
    },
  };

  return similarProducts.length > 0 ? (
    <div className="similar-product-sec">
      <div className={`myContainer`}>
        <h3>Similar Products</h3>

        <Carousel
          draggable={true}
          keyBoardControl={true}
          responsive={responsive}
          infinite={true}
          partialVisible={"false"}
          autoPlay
          autoPlaySpeed={3000}
          arrows={true}
        >
          {similarProducts.map((elem) => {
            return (
              <div key={elem.id} className="my-col">
                <Card className={`main-card cursor-pointer`}>
                  <LazyLoadImage
                    alt={elem.name}
                    style={{
                      height: "100%",
                      width: "100%",
                      minHeight: "230px",
                    }}
                    effect="blur"
                    className="product-img"
                    src={
                      image_server +
                      `/products/${elem.id}/230x230-${elem.main_image}`
                    }
                    onClick={() =>
                      navigate(`/product/${elem.id}/${slug(elem.name)}`)
                    }
                  />

                  <Card.Body className="card-body">
                    <div
                      className="my-card-title"
                      onClick={() =>
                        navigate(`/product/${elem.id}/${slug(elem.name)}`)
                      }
                    >
                      {elem.name.toUpperCase()}
                    </div>
                    <div className="my-card-price">
                      {elem.selling_price !== elem.mrp && (
                        <del className="mrp-price">
                          <Amount amount={elem.mrp} />
                        </del>
                      )}
                      <Amount amount={elem.selling_price} />
                    </div>
                    <AddCartButton elem={elem} cartData={cartData} />
                  </Card.Body>
                </Card>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  ) : null
};

export default SimilarProducts;
