import React from 'react';
import { Spinner } from 'react-bootstrap';

function AppLoader() {
    return (
        <div className="fixed w-full h-full inset-0 z-10 bg-gray-500 bg-opacity-25">
            <div className="flex justify-center items-center w-full h-full">
                <Spinner animation="grow" variant="app" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        </div>
    )
}

export default AppLoader;