import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import styles from "./index.module.scss";
import { fetchProducts } from "../../actions";
import SimpleButton from "../../components/Buttons/SimpleButton";
import ProductList from "./ProductList";
import ProductsContainer from "../../components/ProductsContainer";
import AppLayout from "../../components/AppLayout";
import MetaTags from "../../components/MetaTags";
import "./Product.css";
import { image_server } from "../../actions/constants";

import { useParams } from "react-router-dom";


function ListPage() {
  const dispatch = useDispatch();
  const {categoryId} = useParams()
  const categoriesInfo = useSelector((state) => state.categories);
  const productsInfo = useSelector((state) => state.products);

  const loading = productsInfo.loading;
  const error = productsInfo.error;
  const categories = categoriesInfo.data;
  const activeCategory = categories.find(
    (c) => c.id === parseInt(categoryId)
  );

  const loadProducts = useCallback(() => {
    fetchProducts(dispatch, categoryId);
  }, [dispatch, categoryId]);

  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });

    loadProducts();
  }, [loadProducts]);

  let content = (
    <div className="col-lg-9 pl-4">
      <div className={`product-title ${styles.heading}`}>
        <h3 className={activeCategory ? `${styles.active}` : null}>
          {activeCategory ? activeCategory.name.toUpperCase() : ""}
        </h3>
      </div>
      <ProductList
        categoryId={categoryId}
        productsData={productsInfo.data}
        activeCategory={activeCategory}
      />
    </div>
  );

  if (loading) {
    content = (
      <div className="col-lg-9 pl-4">
        <div style={{ marginLeft: "100px" }}>
          <Spinner animation="grow" variant="app" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (error) {
    content = (
      <div className="flex h-64 justify-center items-center w-full">
        <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
        <SimpleButton onClick={loadProducts} title="Please try again" />
      </div>
    );
  }

  return (
    <AppLayout
      hideBanner={true}
      hidePhoneDetail={true}
      hideSubscribeSection={true}
    >
      <ProductsContainer
        activeCategoryId={categoryId}
      >
        {content}
      </ProductsContainer>

      {activeCategory ? (
        <MetaTags
          title={activeCategory.name}
          description={activeCategory.description}
          image={image_server + "/categories/120x120-" + activeCategory.image}
        ></MetaTags>
      ) : null}
    </AppLayout>
  );
}

export default ListPage;
