import React from 'react';


export const CartSuccessMessage = ({ data, handleViewCart }) => {
    return (
        <div className="notification__content" style={{ background: '#28a745', padding: '10px 18px 10px 18px' }}>
            <div className="notification__message">Product '<b>{data.name}</b>' is added to cart. <button type="button" onClick={handleViewCart} style={{ color: 'blue' }}>(view cart)</button>
            </div>
        </div>
    )
}


export const StockFinished = ({ data }) => {
    return (
        <div className="notification__content" style={{ background: '#ff4719', padding: '10px 18px 10px 18px' }}>
            <div className="notification__message">No more stock is available for the Product '<b>{data.name}</b>'.
            </div>
        </div>
    );
}

export const maxOrder = ({ data, maximumAllowedQuantityPerOrder }) => {
    return (
        <div className="notification__content" style={{ background: '#ff4719', padding: '10px 18px 10px 18px' }}>
            <div className="notification__message">You can not buy more than <b>{maximumAllowedQuantityPerOrder}</b> quantity of '<b>{data.name}</b>'.
            </div>
        </div>
    );
}

export const RemovedSuccess = ({ data, handleViewCart }) => {
    return (
        <div className="notification__content" style={{ background: '#ff4719', padding: '10px 18px 10px 18px' }}>
            <div className="notification__message">Product '<b>{data.name}</b>' is removed from the cart. <button type="button" onClick={handleViewCart} style={{ color: 'blue' }}>(view cart)</button>
            </div>
        </div>
    );
}