export const getFormattedCategoriesHomepage = (categories, homepageCategories) => {

  let cats = [];

  if (homepageCategories) {
    var homepageCategoriesArr = homepageCategories.split("\n").map(item => item.trim());
    if (Array.isArray(homepageCategoriesArr)) {
      homepageCategoriesArr.forEach(homeCat => {

          let idName = homeCat.split(":");

          if(idName[0] && idName[1]){
            cats.push({
              id: parseInt(idName[0].trim()),
              name: idName[1].trim()
            });
        }
      });
    }
  }

  const result = [];

  cats.forEach((elem) => {
    const children = categories.filter((cat) => cat.parent_id === elem.id);
    result.push({
      id: elem.id,
      name: elem.name,
      children,
    });
  });

  return result;
};


export const getFormattedCategoriesSidebar = (categories) => {
  const result = [];
  categories.forEach((elem) => {
    if (elem.parent_id === 1) {
      const children = categories.filter((cat) => cat.parent_id === elem.id);
      result.push({
        id: elem.id,
        name: elem.name,
        image: elem.image,
        children,
      });
    }
  });
  return result;
};


export const toArray = (value, seperator) => {

  let arr = [];

  if (value) {
      arr = value.split(seperator).filter(Boolean).map(v => v.trim());
  }

  return arr;
}