import React, { useState, useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import AppLoader from "../../components/AppLoader";
import MetaTags from '../../components/MetaTags';

import { image_server } from "../../actions/constants";


import { Card, Modal, Image } from "react-bootstrap";
import { fetchImageGallery } from "../../actions/image-gallery";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../actions/breadcrumb";
import { useDispatch } from "react-redux";

function ImageGallery() {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const [currentPhoto, setCurrentPhoto] = useState({});

  const [photos, setPhotos] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Photo Gallery
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  useEffect(() => {

    setLoading(true);

    fetchImageGallery().then(result => {
      setLoading(false);
      if (result.data) {
        setPhotos(result.data);
        window.scrollTo({
          top: 300,
          behavior: "smooth",
        });
      }
    });

  }, []);

  const showFull = (p) => {
    setShow(true);
    setCurrentPhoto(p);
  }

  const gallery = <div className="row">
    {photos.map((photo) => {
      return (
        <div key={photo.id} className="col-md-3 col-sm-4 my-col">
          <Card
            className={`main-card cursor-pointer`}
            onClick={e => showFull(photo)}
          >
            <Card.Img
              style={{ height: "100%", width: "100%" }}
              className="product-img"
              variant="top"
              src={image_server + `/imagegallery/200x200-${photo.image}`}
            />
          </Card>
        </div>
      );
    })}

  </div>

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {loading ? <AppLoader /> : null}
      <div className={` myContainer`}>
        <div className="photo-gallery-sec">
        <h3>Photo Gallery</h3>
        {gallery}
        </div>
      
      </div>

      {currentPhoto.image ?
        <Modal
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>{currentPhoto.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image
              src={image_server + `/imagegallery/600x600-${currentPhoto.image}`}
            />
          </Modal.Body>
        </Modal> : null}

      <MetaTags
        title="Photo Gallery"
        description="Our Photo Gallery"
      ></MetaTags>

    </AppLayout>
  );
}

export default ImageGallery;
