import React, { useCallback, useEffect, useState } from "react";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import globalStyles from "../../styles/global.module.scss";
import styles from "./index.module.scss";
import { image_server } from "../../actions/constants";
import ReactImageZoom from "react-image-zoom";
import AddCartButton from "../../components/AddCartButton";
import { getSingleProduct } from "../../actions/index";
import { toArray } from "../Home/utils";
import Amount from "../../components/Amount";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import UpdateQuantity from "./UpdateQuantity";

function Detail({ product }) {
  const data = product[0];
  const cart = useSelector((state) => state.cart);
  const productInCart = cart.data.filter((elem) => elem.id === data.id);
  const cartData = useSelector((state) => state.cart.data);
  const [quantity, setQuantity] = useState(1);

  const [mainImage, setMainImage] = useState({});

  const [thumbs, setThumbs] = useState([]);

  const productId = data.id;

  const changeImage = (image) => {
    setMainImage({
      zoomPosition: "left",
      width: 300,
      height: 300,
      zoomWidth: 600,
      img: `${image_server}/products/${data.id}/600x600-${image.img}`,
    });
  };

  const getTotalAmount = () => {
    return data.selling_price * productInCart[0].qty;
  };
  const asyncCallsOnLoad = useCallback(async () => {
    try {
      const response = await getSingleProduct(productId);

      const data = response.data;
      // setting main image
      setMainImage({
        zoomPosition: "left",
        width: 300,
        height: 300,
        zoomWidth: 600,
        img: `${image_server}/products/${data.id}/600x600-${data.main_image}`,
      });

      let media = data.media;

      if (media) {
        const thumbs = [];

        let mediaArr = toArray(media, ",");

        mediaArr.forEach((m) => {
          thumbs.push({
            img: m,
          });
        });

        setThumbs(thumbs);
      }
    } catch (err) {
      console.log(err);
      console.log(err.response);
    }
  }, [productId]);

  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
    asyncCallsOnLoad();
  }, [asyncCallsOnLoad]);

  const parseAttributes = (attributes) => {
    let list = attributes.split("\n");

    if (list && Array.isArray(list)) {
      return (
        <table>
          <tbody>
            {list.map((tag) => {
              let keyVal = tag.trim().split(":");
              if (keyVal[0] && keyVal[1]) {
                return (
                  <tr key={keyVal[0].trim()}>
                    <td className={styles.productAttrKey}>
                      {keyVal[0].trim()}
                    </td>
                    <td>:</td>
                    <td className={styles.attrValue}>{keyVal[1].trim()}</td>
                  </tr>
                );
              } else {
                return "";
              }
            })}
          </tbody>
        </table>
      );
    }
  };

  // const searchTags = (tags) => {

  //     let tagsArr = tags.split(",");

  //     return tagsArr.map(tag => {
  //         return <div key={tag.trim()} style={{ backgroundColor: '#f1f1f1', width: 'fit-content' }} className="font-semibold mt-2 p-2 text-xs rounded">{tag.trim()}</div>;
  //     });

  // }

  const parseHighlights = (highlights) => {
    let highlightsArr = highlights.split("\n");

    return (
      <ul className={styles.highlightList}>
        {" "}
        {highlightsArr.map((highlight) => {
          if (highlight) {
            return (
              <li key={highlight.trim()} className={styles.highlightItem}>
                {highlight.trim()}
              </li>
            );
          } else {
            return "";
          }
        })}
      </ul>
    );
  };

  let responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1440 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 1440, min: 992 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: 2,
    },
  };

  return (
    <>
      <div className="col-12 col-lg-9 pl-lg-3 px-0 pro-detail-sec">
        <div className="flex flex-col col-12 col-lg-12 px-0 ">
          {/* Detail Card */}
          <div className={`shadow-md flex flex-col border-solid ${styles.detailCard}`}>
            <div className={`flex px-4 py-2 ${styles.mainContainer}`}>
              <div
                className={`flex flex-col w-3/4 text-left ${styles.leftContainer}`}
              >
                <div className="text-3xl">
                  <p className={`${styles.productName}`}>{data.name}</p>
                </div>
                {/* {searchTags(data.search_tags)} */}

                <div className=" pt-4 sku">
                  <span>SKU</span>: {data.sku}
                </div>
                <div className="py-2 items-center pb-8">
                  <div className="manufacturer text-left">
                    <span>Manufacturer:</span> {data.brand_name}
                  </div>
                  {data.selling_price !== data.mrp && (
                    <div
                      className={` ${styles.priceBlock} ${styles.mrpPriceBlock}`}
                    >
                      <span> Mrp: </span>
                      <del className={globalStyles.appColor}>
                        <Amount amount={data.mrp} />
                      </del>
                    </div>
                  )}
                  
                  <div className={`price-block ${styles.priceBlock}`}>
                    Price:{" "}
                    <span className={globalStyles.appColor}>
                      <Amount amount={data.selling_price} />
                    </span>
                  </div>
                </div>

                <div className={styles.updateQtySec}>
                  <span>Quantity:</span>{" "}
                  <UpdateQuantity
                    quantity={quantity}
                    setQuantity={setQuantity}
                    product={data}
                  />
                </div>
              </div>

              <div
                className={`w-1/2 flex flex-col product_detail ${styles.rightContainer}`}
              >
                <div className="prod_left">
                  {mainImage.img ? <ReactImageZoom {...mainImage} /> : null}

                  <div className={`thum_slider ${styles.thumbs}`}>
                    <Carousel
                      draggable={true}
                      keyBoardControl={true}
                      responsive={responsive}
                      infinite={true}
                      partialVisible={false}
                    >
                      {thumbs.map((thumb) => {
                        return (
                          <img
                            key={"thumb" + thumb.img}
                            onClick={() => changeImage(thumb)}
                            src={`${image_server}/products/${data.id}/120x120-${thumb.img}`}
                            alt=""
                          />
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="px-4 sku">
                            <span>SKU</span>: {data.sku}
                        </div>
                        <div className="px-4 py-2 flex items-center border-solid border-b-2 pb-8">
                            <div className="w-3/4 font-semibold text-xs text-left">Manufacturer: {data.brand_name}</div>
                            <div className="w-1/4 font-semibold">Price: <span className={globalStyles.appColor}>&#36;{data.selling_price}</span></div>
                        </div> */}

            <div
              style={{ backgroundColor: "#f1f1f1" }}
              className="flex  py-2 items-center justify-between"
            >
              {productInCart.length ? (
                <div className="w-1/3 flex flex-col font-semibold">
                  <div>Total Amount</div>
                  <div>&#36;{getTotalAmount().toFixed(2)}</div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="w-2/3 flex justify-end">
                <AddCartButton elem={data} cartData={cartData} qty={quantity} />
              </div>
            </div>
          </div>

          <div className={`${styles.detailCard}`}>
            {data.highlights ? (
              <div className="shadow-md p-4 flex flex-col  mt-8  ">
                <div className="text-2xl">Product Highlights</div>
                <div className="mt-2">
                  {data.highlights
                    ? parseHighlights(data.highlights)
                    : "No Highlights Available"}
                </div>
              </div>
            ) : (
              ""
            )}

            {data.attributes ? (
              <div className="shadow-md p-4 flex flex-col mt-8">
                <div className="text-2xl">Attributes</div>
                <div className="mt-2">
                  {data.attributes
                    ? parseAttributes(data.attributes)
                    : "No attributes Available"}
                </div>
              </div>
            ) : (
              ""
            )}

            {/* Description Card */}
            <div className="shadow-md p-4 flex flex-col  mt-8">
              <div className="text-2xl">Description</div>
              <div className="mt-2">
                {data.description
                  ? parse(data.description)
                  : "No Description Available"}
              </div>
            </div>
          </div>

          {/* <div className="shadow-md p-4 flex flex-col  mt-8">
                <InlineShareButtons
                    config={{
                        alignment: 'center',  // alignment of buttons (left, center, right)
                        color: 'social',      // set the color of buttons (social, white)
                        enabled: true,        // show/hide buttons (true, false)
                        font_size: 16,        // font size for the buttons
                        labels: 'cta',        // button labels (cta, counts, null)
                        language: 'en',       // which language to use (see LANGUAGES)
                        networks: [           // which networks to include (see SHARING NETWORKS)
                            'facebook',
                            'twitter',
                            'whatsapp'
                        ],
                        padding: 12,          // padding within buttons (INTEGER)
                        radius: 4,            // the corner radius on each button (INTEGER)
                        show_total: true,
                        size: 30,

                        // OPTIONAL PARAMETERS
                        url: `http://pillsgo.com/product/${data.id}/${data.name}`, // (defaults to current url)
                        image: image_server + `/products/${productSize}-${data.main_image}`,
                        description: data.description,       // (defaults to og:description or twitter:description)
                        title: data.name,            // (defaults to og:title or twitter:title)
                        message: data.description,     // (only for email sharing)
                        subject: data.name,  // (only for email sharing)
                    }}
                />
            </div> */}
        </div>
      </div>
    </>
  );
}

export default Detail;
