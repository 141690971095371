import React, { useState, useCallback, useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import { Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import SimpleButton from "../../components/Buttons/SimpleButton";
import AppLoader from "../../components/AppLoader";
import { useSelector } from "react-redux";
import { getErrorMsg } from "../../utils";
import { updateUserPassword } from "../../actions/users";
import { showMessage } from "../../actions/constants";
import { setBreadcrumb } from "../../actions/breadcrumb";

import { useDispatch } from "react-redux";

function ChangePassword() {
  const [values, setValues] = useState({
    oldPwd: "",
    newPwd: "",
    confirmPwd: "",
  });
  const [errorText, setErrorText] = useState("");
  const [isChangePasswordInitiated, setIsChangePasswordInitiated] =
    useState(false);
  const navigate = useNavigate();
  const userState = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Change Password
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  const handleChange = useCallback((field, e) => {
    const { value } = e.target;
    setErrorText("");
    setValues((currValues) => {
      return {
        ...currValues,
        [field]: value,
      };
    });
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!values.oldPwd.trim().length) {
        setErrorText("Please enter old password!");
        return false;
      }
      if (values.newPwd.trim().length < 8) {
        setErrorText("Password must be atleast 8 characters long!");
        return false;
      }
      if (values.newPwd !== values.confirmPwd) {
        setErrorText(`Passwords doesn't match`);
        return false;
      }
      setIsChangePasswordInitiated(true);
      try {
        const reqBody = {
          old_password: values.oldPwd,
          new_password: values.newPwd,
        };
        await updateUserPassword(reqBody, userState.data.token);
        setIsChangePasswordInitiated(false);
        // dispatch({ type: USER_LOGGED_OUT });
        navigate("/");
        showMessage("Password Updated Successfully", "success");
      } catch (err) {
        console.log(err);
        setIsChangePasswordInitiated(false);
        setErrorText(getErrorMsg(err));
      }
    },
    [values.confirmPwd, values.newPwd, values.oldPwd, userState.data, navigate]
  );

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {isChangePasswordInitiated ? <AppLoader /> : null}
      <div className="myContainer">
        <div className="contact-sec text-center">
          <h3>Change Password</h3>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                value={values.oldPwd}
                onChange={handleChange.bind(this, "oldPwd")}
                className="mt-2"
                type="password"
                placeholder="Old Password"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                value={values.newPwd}
                onChange={handleChange.bind(this, "newPwd")}
                className="mt-2"
                type="password"
                placeholder="New Password"
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                value={values.confirmPwd}
                onChange={handleChange.bind(this, "confirmPwd")}
                className="mt-2 mb-4"
                type="password"
                placeholder="Re-type Password"
              />
            </Form.Group>
            {errorText.length ? (
              <div className="mb-4 text-red-400 font-semibold">{errorText}</div>
            ) : null}
            <SimpleButton title="Change Password" />
          </Form>
        </div>
      </div>
    </AppLayout>
  );
}

export default ChangePassword;
