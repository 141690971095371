import { combineReducers } from 'redux';
import CategoriesReducer from './categories';
import PackagesReducer from './packages';
import ProductsReducer from './products';
import ProductDetailReducer from './productDetail';
import MetaReducer from './meta';
import UserReducer from './user';
import CartReducer from './cart';
import OrderReducer from './order';
import BreadcrumbReducer from './breadcrumb';

const rootReducer = combineReducers({
    categories: CategoriesReducer,
    packages: PackagesReducer,
    products: ProductsReducer,
    productDetail: ProductDetailReducer,
    meta: MetaReducer,
    user: UserReducer,
    cart: CartReducer,
    order: OrderReducer,
    breadcrumb: BreadcrumbReducer
});

export default rootReducer;
