import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });

    console.log(error, info);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="container">
          <h1
            style={{
              color: "#a94442",
              padding: "100px 10px 80px",
              maxWidth: "1044px",
              width: "100%",
              margin: "0 auto",
              fontSize: 25
            }}
          >
            We are sorry something went wrong.
          </h1>
        </div>
      );
    }
    return children;
  }
}
