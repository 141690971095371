import ajax from '../actions/ajax'


export function createPayment(params) {
  return ajax('/orders/payment', { method: 'POST', data: params });
}

export function verifyPayment(params) {
  return ajax('/orders/verify_payment', { method: 'POST', data: params });
}
