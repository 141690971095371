import React, { useCallback, useState } from "react";
import { FormControl } from "react-bootstrap";
import { useSelector } from "react-redux";
import AppLoader from "../../AppLoader";
import { subscribeToNewsLetter } from "../../../actions";
import { validEmail, getErrorMsg } from "../../../utils";
import { NotificationManager } from "react-notifications";
import "./style.css";
import { showMessage } from "../../../actions/constants";

function SubscriptionSection() {
  const [email, setEmail] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [successText, setSuccessText] = useState(false);
  const [errorText, setErrorText] = useState("");
  const userState = useSelector((state) => state.user);

  const handleEmailChange = useCallback((e) => {
    setSuccessText(false);
    setErrorText("");
    setEmail(e.target.value);
  }, []);

  const subscripeNewsLetter = async (e) => {
    e.preventDefault();
    try {
      if (!email) {
        showMessage('enter your email !', 'danger');
        return;
      }
      if (!validEmail(email)) {
        // alert('enter valid email');
        showMessage('enter valid email !', 'danger');
        // TODO: show snackbar
        return false;
      }
      setSuccessText(false);
      setErrorText("");
      setApiCalled(true);
      const response = await subscribeToNewsLetter(email);
      if (response.data) {
        NotificationManager.success(response.data.message, "Subscribe");
      }
      setSuccessText(true);
      setApiCalled(false);
    } catch (err) {
      console.log(">>>", err.response.data.message);
      NotificationManager.error(err.response.data.message, "Error");
      setErrorText(getErrorMsg(err));
      setApiCalled(false);
    }
  };

  if (userState.userDetails && userState.userDetails.newsletter_subscribed) {
    return null;
  }

  return (
    <div className="subscription-sec">
      {apiCalled ? <AppLoader /> : null}

      <div className="myContainer">
        <div className="block">
          <h3>Newsletter</h3>
          <h5> Subscribe to get the latest news & updates.</h5>

          <div className="input-block">
            <FormControl
              onChange={handleEmailChange}
              value={email}
              placeholder="Enter you email address"
              aria-label="Email address"
              aria-describedby="basic-addon2"
              className="form-input"
            />
            {/* <SimpleButton onClick={subscripeNewsLetter} title="Send Me" /> */}
            <input type="submit" value="Send Me" className="my-btn" onClick={subscripeNewsLetter} />
            <div className="msg-block">
              {successText ? <div className="text-green-400 font-semibold">Successfully Subscribed</div> : null}
              {errorText ? (
                <div className="text-red-400 font-semibold">{errorText}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default SubscriptionSection;
