

import { Store } from 'react-notifications-component';
import * as CartMessages from "../components/CartSuccessMessage";


// export const server_url = "https://rajvasantexport.com/pillsgo-backend/api/web";
// export const image_server = "https://rajvasantexport.com/pillsgo-backend/data";

export const server_url = "https://rajvasantexport.com/backend/api/web";
export const image_server = "https://rajvasantexport.com/backend/data";


// export const server_url = "http://localhost:8888/projects/zarrcom-backend/api/web";
// export const image_server = "http://localhost:8888/projects/zarrcom-backend/data";

export const bannerSize = "1420x436";
export const innerBannerSize = "1420x256";
export const homePageCategorySize = "292x237";
export const homePagePackageSize = "418x200";
export const productSize = "600x600";

export const slug = (text) => {
  if (text) {
    return text
      .toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }
  return '';
}

// this function is used to show the popup error/success messages.
export const setMessage = (type, message) => {

  let messages = localStorage.getItem("popup_messages");

  let messagesArr = [];

  if (messages) {
    messagesArr = JSON.parse(messages);
  }

  messagesArr.push({
    "type": type,
    "message": message
  });

  localStorage.setItem("popup_messages", JSON.stringify(messagesArr));
}

// this function is used to return the data for popup error/success messages.
export const getMessages = () => {

  let messages = localStorage.getItem("popup_messages");

  let messagesArr = [];

  if (messages) {
    messagesArr = JSON.parse(messages);
  }

  localStorage.removeItem("popup_messages");

  return messagesArr;

}

export const showMessage = (message, type, duration = 2000) => {
  Store.addNotification({
    message,
    type,
    insert: "top",
    container: "bottom-right",
    dismiss: {
      duration,
      showIcon: true,
    }
  });
}

export const loginMessage = () => {
  showMessage("You must be login to proceed.", "warning", 3000);
}


export const cartAddedMessage = (data, handleViewCart) => {
  Store.addNotification({
    content: CartMessages.CartSuccessMessage(data, handleViewCart),
    insert: "top",
    container: "bottom-right",
    dismiss: {
      duration: 5000,
      showIcon: true,
    }
  });
}


export const stockFinishedMessage = (data) => {
  Store.addNotification({
    content: CartMessages.StockFinished(data),
    insert: "top",
    container: "bottom-right",
    dismiss: {
      duration: 2000,
      showIcon: true,
    }
  });
}

export const maxOrderMessage = (data, maximumAllowedQuantityPerOrder) => {
  Store.addNotification({
    content: CartMessages.maxOrder(data, maximumAllowedQuantityPerOrder),
    insert: "top",
    container: "bottom-right",
    dismiss: {
      duration: 1000,
      showIcon: true,
    }
  });
}

export const cartRemovedMessage = (data, handleViewCart) => {
  Store.addNotification({
    content: CartMessages.RemovedSuccess(data, handleViewCart),
    insert: "top",
    container: "bottom-right",
    dismiss: {
      duration: 3000,
      showIcon: true,
    }
  });
}