import React from 'react';
import SideList from '../Cart/SideList';
import SimpleButton from '../../components/Buttons/SimpleButton';

function Detail({ orderItems, data, handleReorder }) {

    let order_status = data.order ? <div className="py-2 float-right"><span className="font-bold">Order Status:</span> <span className=" text-teal-700 font-bold" >{data.order.status}</span></div> : null;

    let shipping_details = data.customer ? (<div className="mt-3">
        <div className="border border-transparent border-solid p-4 bg-gray-200 rounded">

            <div className="py-2"><span className="font-bold">Mobile Number:</span> {data.customer.mobile_number}</div>
            <div className="py-2"><span className="font-bold">Name:</span> {data.customer.name}</div>
            <div className="py-2"><span className="font-bold">Address:</span> {data.customer.address}</div>
            {data.customer.city ? <div className="py-2"><span className="font-bold">City:</span> {data.customer.city}</div> : null}
            {data.customer.country ? <div className="py-2"><span className="font-bold">Country:</span> data.customer.country</div> : null}
            <div className="py-2"><span className="font-bold">Zip Code:</span> {data.customer.pin_code}</div>
            {data.customer.additional_mobile_number ? <div className="py-2"><span className="font-bold">Alternate Mobile Number:</span> {data.customer.additional_mobile_number}</div> : null}
        </div>
    </div>) : 'loading...';

    let side_list = (data.order && orderItems) ? <SideList cartState={orderItems} courierCharges={data.order.delivery_charges} /> : 'loading...';

    return (
        <>

            <div className="row w-full">
                <div className="col-12 col-lg-8 border-solid border-black border-r-2">

                    <div className="grid grid-rows-2 grid-flow-col gap-4 mt-3">
                        <div className="row-span-2">
                            <div className="font-bold text-xl">Shipping Details</div>
                        </div>
                        <div className="row-span-2">{order_status}</div>
                    </div>

                    <div>
                        {shipping_details}
                    </div>

                    <div className="pt-4">
                        <SimpleButton title="Reorder" onClick={handleReorder.bind(this, data.order)} />
                    </div>

                </div>

                {side_list}

            </div>
        </>
    )
}

export default Detail;