import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeaturedProducts from "./FeaturedProducts";
import { fetchFeaturedProducts } from "../../actions";
import { getFormattedCategoriesHomepage } from "./utils";
import { formatCompanySettings } from "../../components/AppLayout/utils";
import { Spinner } from "react-bootstrap";
import AppLayout from "../../components/AppLayout";
import MetaTags from "../../components/MetaTags";
import OfferSec from "./OfferSec";
import AboutUsSec from "./AboutUsSec";

function Home() {
  const dispatch = useDispatch();

  const categoriesInfo = useSelector((state) => state.categories);
  const { companySettings } = useSelector((state) => state.meta);

  const products = useSelector((state) => state.products);

  const settings = formatCompanySettings(companySettings);

  const loadFeaturedProducts = useCallback(() => {
    fetchFeaturedProducts(dispatch);
  }, [dispatch]);

  useEffect(() => {
    loadFeaturedProducts();
  }, [loadFeaturedProducts, categoriesInfo.data.length]);

  let formattedCategories = getFormattedCategoriesHomepage(
    categoriesInfo.data,
    settings.homepageCategories
  );

  const loading = products.loading;
  // const error = products.error;

  let content = null;

  content = (
    <>
      {formattedCategories.map((cat) => {
        if (cat.children.length) {
          return <FeaturedProducts key={cat.id} category={cat} />;
        } else {
          return null;
        }
      })}

      <OfferSec />

      <AboutUsSec detailsAboutCompany={settings?.detailsAboutCompany} />

      <FeaturedProducts
        featuredProducts={products.featured}
        homePageFeaturedProductsMoreButtonUrl={
          settings.homePageFeaturedProductsMoreButtonUrl
        }
      />
    </>
  );

  if (loading) {
    content = (
      <div className="flex h-20 justify-center items-center">
        <Spinner animation="grow" variant="app" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  // if (error) {
  //   content = (
  //     <div className="flex h-20 justify-center items-center">
  //       <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
  //       <SimpleButton onClick={() => {}} title="Please try again" />
  //     </div>
  //   );
  // }

  return (
    <AppLayout>
      {content}

      <MetaTags
        title="Welcome"
        description="We deal in all kind of Home Needs at a very affordable rate."
      ></MetaTags>
    </AppLayout>
  );
}

export default Home;
