import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "./Footer.css";

function Footer({
  menu,
  companyContactEmail,
  companyContactNumber,
  companyContactAddress,
}) {
  console.log(
    "🚀 ~ file: index.js ~ line 12 ~ companyContactEmail",
    companyContactEmail
  );
  console.log("🚀 ~ file: index.js ~ line 12 ~ menu", menu);

  const keys = {
    "footer-company-menu": "QUICK LINKS",
    "footer-shopping-menu": "CONTACT US",
    "footer-categories-menu": "Categories",
  };

  const footerMenu = [];
  Object.keys(keys).forEach((k, index) => {
    const items = menu.filter((elem) => elem.category === k);
    if (items.length) {
      footerMenu.push({
        id: index,
        name: keys[k],
        items,
      });
    }
  });

  return (
    <footer>
      <div className={`${styles.footertop}`}>
        <div className="myContainer">
          <div className="row">
            {footerMenu.map((elem) => {
              return (
                <div
                  key={elem.id}
                  className="px-0 col-sm-12 col-lg-6 col-md-6 quick-links-col"
                >
                  <div className={`footer-title ${styles.list1}`}>
                    {elem.name}
                  </div>
                  <div className={`quick-links  ${styles.list}`}>
                    {elem.items.map((item) => {
                      return (
                        <Link key={item.id} to={item.url}>
                          {item.name}
                        </Link>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="px-0 col-sm-12 col-lg-3 mb-4 col-md-3 footer-contact-col">
              <div className={`footer-title ${styles.list1} mb-2`}>
                CONTACT US
              </div>
              <div className=" pn-">
                <FontAwesomeIcon className="mr-1" icon={faPhoneAlt} />
                &nbsp;&nbsp;&nbsp;
                <a href={`tel: ${companyContactNumber}`}>
                  {companyContactNumber}
                </a>
              </div>
              <br></br>
              <div className="envelopeBlock  ">
                {" "}
                <FontAwesomeIcon className="mr-1" icon={faEnvelope} />
                &nbsp;&nbsp;&nbsp;
                <a href={`mailto: ${companyContactEmail}`}>
                  {companyContactEmail}
                </a>
              </div>

              <div className={`${styles.list2} footer-contact2-col mt-3`}>
                <div className="footer-contact addressBlock px-0">
                  <FontAwesomeIcon
                    className="mapMarker mr-3"
                    icon={faMapMarkerAlt}
                  />
                  {companyContactAddress}
                </div>
              </div>
            </div>
            {/* <div className={`${styles.list1} font-bold`}></div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
