import React from "react";
import styles from "./index.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function SocialSection({ companyContactAddress, topHeaderMsg }) {
  return (
    <div>
      <ul className={`${styles.socialItems}`}>
        <li className={`${styles.socialItem}`}>
          <FontAwesomeIcon className="mr-2" icon={faEnvelope} />
          <a href={`mailto:${companyContactAddress}`}>{topHeaderMsg}</a>
        </li>
      </ul>
    </div>
  );
}

export default SocialSection;
