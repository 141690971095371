import React from "react";
import AppLayout from "../../components/AppLayout";
import "./NotFound.css"

function NotFound() {
  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      <div className={`myContainer `}>
        <div className="block">
          <div className="not-found">
            <div className="oops-img">
              <img src="/images/b3.jpg" alt=""/>
            </div>
            <h2>404 page not found!</h2>
        </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default NotFound;
