import React, { useState, useEffect } from "react";
import { Card, Modal } from "react-bootstrap";

import AppLayout from "../../components/AppLayout";
import AppLoader from "../../components/AppLoader";
import MetaTags from "../../components/MetaTags";
import { fetchVideoGallery } from "../../actions/video-gallery";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setBreadcrumb } from "../../actions/breadcrumb";

function VideoGallery() {
  const [loading, setLoading] = useState(false);

  const [currentVideo, setCurrentVideo] = useState({});

  const [videos, setVideos] = useState([]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const dispatch = useDispatch();
  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Video Gallery
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);

    fetchVideoGallery().then((result) => {
      setLoading(false);
      if (result.data) {
        setVideos(result.data);
        window.scrollTo({
          top: 300,
          behavior: "smooth",
        });
      }
    });
  }, []);

  const showFull = (p) => {
    setShow(true);
    setCurrentVideo(p);
  };

  const gallery = (
    <div className="row pt-2">
      {videos.map((video) => {
        return (
          <div key={video.id} className="col-md-3 col-sm-4 my-col">
            <Card
              className={`main-card cursor-pointer`}
              onClick={(e) => showFull(video)}
            >
              <Card.Img
                style={{ height: "100%", width: "100%" }}
                className="product-img"
                variant="top"
                src={`https://img.youtube.com/vi/${video.video_id}/hqdefault.jpg`}
              />
            </Card>
          </div>
        );
      })}
    </div>
  );

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      {loading ? <AppLoader /> : null}
      <div className={`myContainer`}>
        <div className="photo-gallery-sec ">
          <h3>Video Gallery</h3>
          {gallery}
        </div>
      </div>

      {currentVideo.video_id ? (
        <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>{currentVideo.name}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <iframe
              width="770"
              height="470"
              src={`https://www.youtube.com/embed/${currentVideo.video_id}?controls=0&autoplay=1`}
              title={currentVideo.name}
              frameborder="0"
              autoplay="true"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </Modal.Body>
        </Modal>
      ) : null}

      <MetaTags
        title="Video Gallery"
        description="Our Video Gallery"
      ></MetaTags>
    </AppLayout>
  );
}

export default VideoGallery;
