import React from "react";

function SimpleButton(props) {
  return (
    <button
      disabled={props.disabled}
      onClick={props.onClick}
      className={`my-btn ${props.className}`}
      variant="primary"
      style={props.style}
    >
      {props.title}
    </button>
  );
}

export default SimpleButton;
