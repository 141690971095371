import React, { useCallback } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

import {
  faUserAlt,
  faShoppingCart,
  faSignInAlt,
} from "@fortawesome/free-solid-svg-icons";

import * as Icons from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import {
  CURRENT_CURRENCY_CHANGED,
  USER_LOGGED_OUT,
} from "../../../../reducers/types";
import { showMessage } from "../../../../actions/constants";

import { useLocation } from "react-router-dom";

function LogoSection({ companySettings }) {
  const userState = useSelector((state) => state.user);
  const cartState = useSelector((state) => state.cart);
  const cartQty = cartState.data.length;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const handleLogout = useCallback(() => {
    showMessage("Logged out successfully.", "success");

    dispatch({ type: USER_LOGGED_OUT });
    navigate("/login");
  }, [dispatch, navigate]);

  const handleCartClick = useCallback(() => {
    navigate("/cart");
  }, [navigate]);

  const currency = useSelector((state) => state.meta.currentCurrency);
  const supportedCurrencies = useSelector(
    (state) => state.meta.supportedCurrencies
  );

  // console.log("supportedCurrencies::", supportedCurrencies);

  const changeCurrency = (currency) => {
    if (location.pathname === "/checkout") {
      showMessage(`You can't change the currency on this page.`, "danger");
    } else {
      dispatch({ type: CURRENT_CURRENCY_CHANGED, payload: currency });
      showMessage(
        `Currency changed successfully to ${currency.code}.`,
        "success"
      );
      document.body.click();
    }
  };

  const getCurrencyMenuIcon = useCallback((currency) => {
    return currency?.code ? (
      <>
        <FontAwesomeIcon
          className={`cursor-pointer ${styles.icons}`}
          color="#696969"
          fontSize="14px"
          icon={Icons[currency.icon]}
        />{" "}
        {currency.name}
      </>
    ) : null;
  }, []);

  const currencyPopover = (
    <Popover id="currency-popover">
      <Popover.Body>
        {supportedCurrencies.map((currency) => (
          <div
            key={currency.code.toString()}
            onClick={() => changeCurrency(currency)}
            className="py-2 cursor-pointer"
          >
            <FontAwesomeIcon
              color="#696969"
              fontSize="13px"
              icon={Icons[currency.icon]}
              style={{marginRight: 3}}
            />
            {currency.name}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {userState.data ? (
          <div
            onClick={() => navigate("/my-profile")}
            className="border-b-2 py-2 cursor-pointer"
          >
            My Profile
          </div>
        ) : null}
        {userState.data ? (
          <div
            onClick={() => navigate("/orders")}
            className="border-b-2 py-2 cursor-pointer"
          >
            My Orders
          </div>
        ) : null}
        {userState.data ? (
          <div
            onClick={() => navigate("/change-password")}
            className="border-b-2 py-2 cursor-pointer"
          >
            Change Password
          </div>
        ) : null}
        {userState.data ? (
          <div onClick={handleLogout} className="py-2 cursor-pointer">
            Logout
          </div>
        ) : (
          <div
            onClick={() => navigate("/login")}
            className="py-2 cursor-pointer"
          >
            Sign up/login
          </div>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.block}`}>
        <div className={`${styles.companyLogo}`}>
          <Link to="/">
            <img src="/images/logo.jpg" alt="logo" />
          </Link>
        </div>

        <div className={`${styles.userSec}`}>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            rootClose={true}
            overlay={currencyPopover}
          >
            <div className={`${styles.currencyBlock}`}>
              <h6>Currency ({getCurrencyMenuIcon(currency)})</h6>
            </div>
          </OverlayTrigger>

          <div onClick={handleCartClick} className={`flex ${styles.cartBlock}`}>
            <FontAwesomeIcon
              className={`mr-3 cursor-pointer ${styles.icons}`}
              color="#696969"
              icon={faShoppingCart}
            />

            <span>{cartQty}</span>
            <h6>My Cart</h6>
          </div>

          {userState.data ? (
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              rootClose={true}
            >
              <div className={`${styles.userBlock}`}>
                <FontAwesomeIcon
                  className={`cursor-pointer ${styles.icons}`}
                  color="#696969"
                  fontSize="50px"
                  icon={faUserAlt}
                />
                <h6>My Account</h6>
              </div>
            </OverlayTrigger>
          ) : (
            <div className={`${styles.loginBlock}`}>
              <FontAwesomeIcon color="#696969" icon={faSignInAlt} /> &nbsp;
              <Link to="/login">Login</Link>

              {/* {localStorage.getItem("userInfo") ? (
                <Link to="#." onClick={handleLogout}>
                  LogOut
                </Link>
              ) : (
                <Link to="/login">Login</Link>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LogoSection;
