import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setBreadcrumb } from '../../actions/breadcrumb';
import AppLayout from '../../components/AppLayout';
import CheckoutSummary from './CheckoutSummary';

function Checkout() {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    
    useEffect(() => {
        let breadcrumb = (
          <>
            <Link to="/">Home</Link>
            <Link className="active" to="#">
              / Checkout
            </Link>
          </>
        );
        setBreadcrumb(dispatch, breadcrumb);
      }, [dispatch]);

    if (!location.state || !location.state.shippingData) {
        navigate("/cart");
        return false;
    }

    let content = (
        <>
            <CheckoutSummary shippingData={location.state.shippingData} />
        </>
    )

    return (
        <AppLayout hideSubscribeSection={true} hideBanner={true} hidePhoneDetail={true}>
            <div className={`myContainer my-5`}>
                {content}
            </div>
        </AppLayout>
    )
}

export default Checkout;