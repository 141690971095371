import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductCategories } from "../../actions";
import { Spinner } from "react-bootstrap";
import SimpleButton from "../Buttons/SimpleButton";
import { getFormattedCategoriesSidebar } from "../../views/Home/utils";
import CategorySideList from "../../views/ListPage/CategorySideList";
import "./Product.css";
import Filter from "../../views/ListPage/Filter";

function ProductsContainer(props) {
  const dispatch = useDispatch();
  const [visibleCategories, setVisibleCategories] = useState([]);

  const categoriesInfo = useSelector((state) => state.categories);
  const loading = categoriesInfo.loading;
  const error = categoriesInfo.error;
  const categories = categoriesInfo.data;
  const activeCategory = categories.find(
    (c) => c.id === parseInt(props.activeCategoryId)
  );
  const formattedCategories = getFormattedCategoriesSidebar(categories);

  const loadCategories = useCallback(() => {
    fetchProductCategories(dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!categories.length) {
      loadCategories();
    }
  }, [loadCategories, categories.length]);

  useEffect(() => {
    if (activeCategory) {
      setVisibleCategories([activeCategory.parent_id]);
    }
  }, [activeCategory]);

  const toggleVisibleCategories = useCallback(
    (id) => {
      const index = visibleCategories.indexOf(id);
      if (index === -1) {
        setVisibleCategories((v) => {
          return [...v, id];
        });
      } else {
        setVisibleCategories((v) => {
          return [...v.slice(0, index), ...v.slice(index + 1)];
        });
      }
    },
    [visibleCategories]
  );

  if (loading) {
    return (
      <div className="flex h-20 justify-center items-center">
        <Spinner animation="grow" variant="app" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex h-20 justify-center items-center">
        <div className="font-bold text-xl mr-2">Something Went Wrong!</div>
        <SimpleButton onClick={loadCategories} title="Please try again" />
      </div>
    );
  }

  console.log('props::', props)

  return (
    <div className={`myContainer`}>
      <div className="products-sec text-left">
        <div className="row">
          <div className="col-lg-3 d-none d-lg-block pr-2 px-0">
            <h3 className="text-left">Categories</h3>
            <CategorySideList
              visibleCategories={visibleCategories}
              formattedCategories={formattedCategories}
              toggleVisibleCategories={toggleVisibleCategories}
              activeCategory={activeCategory}
            />
            <br />
            <br />
            <Filter />
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProductsContainer);
