import React, { useState, useCallback, useEffect } from "react";
import SimpleButton from "../../components/Buttons/SimpleButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import { formatCompanySettings, toArray } from "../../utils";
import { authKey_local_storage_key } from "../../helpers/common";
import { fetchCourierRegions } from "../../actions/order";


function AddressForm({ handleAddressSubmit, handleBackOperation }) {

  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = localStorage.getItem(authKey_local_storage_key);
    fetchCourierRegions(dispatch, authToken);
  }, [dispatch]);

  const customerState = useSelector((state) => state.user.data.customer);
  const courierRegions = useSelector((state) => state.order.courierRegions.data);

  const { companySettings } = useSelector((state) => state.meta);

  const settings = formatCompanySettings(companySettings);

  const supportedCountries = toArray(settings?.supportedCountries, "\n") || [];

  const supportedCountriesOptions = [
    <option key="default" value="">Select Country</option>,
    ...supportedCountries.map((country) => {
      const keyValue = toArray(country, "=>");
      return <option key={keyValue[0]} value={keyValue[0]}>{keyValue[1]}</option>;
    })
  ];

  const [selectedCountry, setSelectedCountry] = useState("");

  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({
    name: "",
    address: "",
    mobile_number: "",
    pincode: "",
    city: "",
    district: "",
    state: "",
    country: "",
    additional_mobile_number: "",
    courier_region_id: "",
  });

  useEffect(() => {
    setSelectedCountry(values.country);
    setValues((currVal) => {
      return {
        ...currVal,
        courier_region_id: "",
      };
    });
  }, [values.country]);

  const courierRegionsOptions = [
    <option key="default" value="">Select Courier Option</option>,
    ...courierRegions.filter(region => region.country_code === selectedCountry).map((region) => {
      return <option key={region.id} value={region.id}>{region.name}</option>;
    })
  ];


  useEffect(() => {
    if (customerState) {
      setValues((currVal) => {
        return {
          ...currVal,
          address: customerState.address
            ? customerState.address
            : "",
          name: customerState.name ? customerState.name : "",
          mobile_number: customerState.mobile_number ? customerState.mobile_number : "",
          pincode: customerState.pin_code
            ? customerState.pin_code
            : "",
          city: customerState.city ? customerState.city : "",
          country: customerState.country ? customerState.country : "",
          district: customerState.district
            ? customerState.district
            : "",
          state: customerState.state ? customerState.state : "",
          courier_region_id: customerState
            .courier_region_id
            ? customerState.courier_region_id
            : "",
          additional_mobile_number: customerState
            .additional_mobile_number
            ? customerState.additional_mobile_number
            : "",
        };
      });
    }
  }, [customerState]);

  const handleFieldChange = useCallback(async (field, e) => {
    e.persist();
    setValues((currData) => {
      return {
        ...currData,
        [field]: e.target.value
      };
    });
    setErrors((currErr) => {
      return {
        ...currErr,
        [field]: false,
      };
    });
  }, []);


  const handleSubmit = useCallback(
    async (e) => {

      e.preventDefault();

      if (!values.name || !values.name.trim().length) {
        setErrors((currErr) => {
          return {
            ...currErr,
            name: true,
          };
        });
        return false;
      }

      if (!values.mobile_number) {
        setErrors((currErr) => {
          return {
            ...currErr,
            mobile_number: true,
          };
        });
        return false;
      }
      if (!values.address || !values.address.trim()) {
        setErrors((currErr) => {
          return {
            ...currErr,
            address: true,
          };
        });
        return false;
      }

      if (!values.country || !values.country.trim()) {
        setErrors((currErr) => {
          return {
            ...currErr,
            country: true,
          };
        });
        return false;
      }

      if (!values.courier_region_id) {
        setErrors((currErr) => {
          return {
            ...currErr,
            courier_region_id: true,
          };
        });
        return false;
      }

      if (!values.pincode) {
        setErrors((currErr) => {
          return {
            ...currErr,
            pincode: true,
          };
        });
        return false;
      }

      const data = {
        ...values,
        pin_code: values.pincode,
      };
      delete data.pincode;
      handleAddressSubmit(data);
    },
    [handleAddressSubmit, values]
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>Name*</Form.Label>
        <Form.Control
          className="py-3"
          value={values.name}
          onChange={handleFieldChange.bind(this, "name")}
          type="text"
          placeholder="Your Name"
        />
        {errors.name ? (
          <Form.Text className="text-red-500">Please enter name</Form.Text>
        ) : null}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Address*</Form.Label>
        <Form.Control
          className="py-3"
          value={values.address}
          onChange={handleFieldChange.bind(this, "address")}
          as="textarea"
          type="text"
          placeholder="Your Full Address"
        />
        {errors.address ? (
          <Form.Text className="text-red-500">
            Please enter your complete address
          </Form.Text>
        ) : null}
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Mobile Number*</Form.Label>
        <Form.Control
          className="py-3"
          value={values.mobile_number}
          onChange={handleFieldChange.bind(this, "mobile_number")}
          type="text"
          placeholder="Your Mobile Number"
        />
        {errors.mobile_number ? (
          <Form.Text className="text-red-500">
            Please enter a valid mobile number
          </Form.Text>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Country*</Form.Label>
        <Form.Select aria-label="Select your country" onChange={handleFieldChange.bind(this, "country")}>
          {supportedCountriesOptions}
        </Form.Select>
        {errors.country}
        {errors.country ? (
          <Form.Text className="text-red-500">
            Please select your country
          </Form.Text>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Pincode*</Form.Label>
        <Form.Control
          className="py-3"
          value={values.pincode}
          onChange={handleFieldChange.bind(this, "pincode")}
          type="text"
          placeholder="Your Pin Code"
        />
        {errors.pincode}
        {errors.pincode ? (
          <Form.Text className="text-red-500">
            Please enter a valid pin code
          </Form.Text>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Courier Option*</Form.Label>
        <Form.Select aria-label="Select your country" onChange={handleFieldChange.bind(this, "courier_region_id")}>
          {courierRegionsOptions}
        </Form.Select>
        {errors.courier_region_id}
        {errors.courier_region_id ? (
          <Form.Text className="text-red-500">
            Please select courier option
          </Form.Text>
        ) : null}
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Additional Mobile Number</Form.Label>
        <Form.Control
          className="py-3"
          value={values.additional_mobile_number}
          onChange={handleFieldChange.bind(this, "additional_mobile_number")}
          type="text"
          placeholder="Your Alternate Mobile Number"
        />
        {errors.additional_mobile_number ? (
          <Form.Text className="text-red-500">
            Please enter a valid mobile number
          </Form.Text>
        ) : null}
      </Form.Group>

      <br />

      <Form.Group className="mb-3">
        <SecondaryButton
          className="mr-2"
          title="BACK"
          onClick={handleBackOperation}
        />
        <SimpleButton title="Checkout" type="submit" />
      </Form.Group>
    </Form>
  );
}

export default AddressForm;
