import {
  META_LOADING,
  META_ERROR,
  META_LOADED,
  CURRENT_CURRENCY_CHANGED,
  SUPPORTED_CURRENCIES_LOADED,
} from "./types";

const defaultState = {
  menu: [],
  companySettings: [],
  banners: [],
  brands: [],
  loading: false,
  error: false,
  meta_loaded: false,
  currentCurrency: {},
  supportedCurrencies: [],
};

export default function meta(state = defaultState, action) {
  switch (action.type) {
    case META_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        menu: [],
        companySettings: [],
        banners: [],
      };
    case META_ERROR:
      return {
        ...state,
        error: true,
        loading: false,
        menu: [],
        companySettings: [],
        banners: [],
      };
    case CURRENT_CURRENCY_CHANGED:
      localStorage.setItem("current_currency", JSON.stringify(action.payload));
      return { ...state, currentCurrency: action.payload };
    case SUPPORTED_CURRENCIES_LOADED:
      return { ...state, supportedCurrencies: action.payload };
    case META_LOADED:
      return {
        ...state,
        error: false,
        loading: false,
        menu: action.menu,
        companySettings: action.companySettings,
        banners: action.banners,
        brands: action.brands,
        meta_loaded: true,
      };
    default:
      return state;
  }
}
