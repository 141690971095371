import React from "react";
import { Carousel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { bannerSize, image_server } from "../../../actions/constants";
import { getContentByCategoryName } from "../../../helpers/common";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";

function HomeBannerSection() {
  const banners = useSelector((state) => state.meta.banners);

  const homepage_banners = getContentByCategoryName(
    banners,
    "Homepage Banners"
  );
  return (
    <Carousel controls={false} interval={3000}>
      {homepage_banners.map((banner) => {
        return (
          <Carousel.Item key={banner.id}>
            <Link to={banner.url}>
              <img
                className={styles.bannerImg}
                src={image_server + `/banners/${bannerSize}-${banner.image}`}
                alt={banner.name}
              />
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default HomeBannerSection;
