// import { createAction } from 'redux-actions';
import { SET_BREADCRUMB } from '../reducers/types';


// const setBreadcrumbAction = createAction(SET_BREADCRUMB);

// export function setBreadcrumb(breadcrumb) {
//     return dispatch => dispatch(setBreadcrumbAction({ breadcrumb }));
// }


export function setBreadcrumb(dispatch, breadcrumb) {
  return dispatch({ type: SET_BREADCRUMB, payload: breadcrumb });
}