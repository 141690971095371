import React from "react";
import SimpleButton from "../../components/Buttons/SimpleButton";
import { getStatusColor } from "../../utils";

function OrderCard({ order, handleReorder }) {
  
  return (
    <div
      style={{
        border: "1px solid #bd9848",
        width: "20rem",
      }}
      className="w-96 rounded h-48 flex flex-col mt-4 p-2 border-solid"
    >
      <div
        style={{ color: getStatusColor(order.order_status) }}
        className="font-bold"
        >
        {order.order_status}
      </div>

      <div className="text-sm font-bold mt-1">
        Placed on: {order.order_date} 
      </div>

      <div className="text-md font-bold mt-1">
       Order ID: <b>{order.order_id}</b>
      </div>
      <div className="mt-2 text-sm">
        <div className="truncate">{order.shipping_address}</div>
        <div className="truncate">{order.customer.city}</div>
        <div className="truncate">
          {order.customer.state + ` - ${order.pin_code}`}
        </div>
      </div>
      <div className="flex mt-2 flex-1 items-end justify-end">
        <SimpleButton
          style={{ padding: "0.5rem" }}
          onClick={handleReorder.bind(this, order)}
          title="Reorder"
        />
      </div>
    </div>
  );
}

export default OrderCard;
