import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { findProductInCart } from "../../helpers/cart";
import "../../index.css";
import { cartAddedMessage } from "../../actions/constants";
import CommonButton from "../CommonButton";
import {
  getUpdatedCartAfterAddProduct,
  getUpdatedCartAfterRemoveProduct,
} from "../../utils";
import { updateUserCart } from "../../actions/users";
import { CART_UPDATED } from "../../reducers/types";
import { showMessage } from "../../actions/constants";

export default function AddCartButton({ elem, cartData, qty = 1 }) {
  const userState = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleViewCart = useCallback(() => {
    navigate(`/cart`);
  }, [navigate]);

  const addProductToCart = useCallback(
    (data) => {
      cartAddedMessage({ data, handleViewCart });
      const newCart = getUpdatedCartAfterAddProduct(cart.data, data, qty);
      if (userState.data) {
        updateUserCart(JSON.stringify(newCart), userState.data.token);
      }

      dispatch({ type: CART_UPDATED, payload: newCart });
    },
    [handleViewCart, cart.data, qty, userState.data, dispatch]
  );

  const getCartButton = (elem) => {
    if (elem.available_stock <= 0 || elem.selling_price === 0) {
      return (
        <Button style={{ background: "gray", color: "#fff" }} disabled>
          Out Of Stock
        </Button>
      );
    }
    return (
      <CommonButton rounded onClick={(e) => addProductToCart(elem)}>
        {" "}
        Add to Cart{" "}
      </CommonButton>
    );
  };

  const removeProductFromCart = useCallback(
    (data, reduced_quantity = qty) => {
      showMessage(
        "Product '" + data.name + "' is removed from cart.",
        "success"
      );
      const newCart = getUpdatedCartAfterRemoveProduct(
        cart.data,
        data,
        reduced_quantity
      );
      updateUserCart(JSON.stringify(newCart), userState.data);
      dispatch({ type: CART_UPDATED, payload: newCart });
    },
    [dispatch, qty, cart.data, userState.data]
  );

  let addToCartButton = getCartButton(elem);

  const removeFromCartButton = (
    <CommonButton
      className="card-btn added-btn"
      rounded
      onClick={(e) => removeProductFromCart(elem)}
    >
      {" "}
      Remove from Cart{" "}
    </CommonButton>
  );

  return (
    <>
      {findProductInCart(cartData, elem)
        ? removeFromCartButton
        : addToCartButton}
    </>
  );
}
