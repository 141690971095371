import { USER_LOGGED_OUT, CART_UPDATED, CLEAR_CART, LOAD_CART_DATA } from './types';
import { cartData_local_storage_key } from '../../src/helpers/common'

const defaultState = {
    data: [],
};

export default function cart(state = defaultState, action) {
    switch (action.type) {
        case CART_UPDATED:
            localStorage.setItem(cartData_local_storage_key, JSON.stringify(action.payload));
            return { ...state, data: action.payload };
        case LOAD_CART_DATA:
            let data = localStorage.getItem(cartData_local_storage_key) || '[]';
            try {
                return { ...state, data: JSON.parse(data) };
            } catch (e) {
                return { ...state, data: [] };
            }
        case USER_LOGGED_OUT:
        case CLEAR_CART:
            localStorage.removeItem(cartData_local_storage_key);
            return { ...state, data: [] };
        default:
            return state;
    }
}
