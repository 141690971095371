import { useState, useEffect, useRef } from "react";
import { resendEmailOtp } from '../../actions/users'
import { showMessage } from "../../actions/constants";

const useResendOTP = ({
  maxTime,
  onTimerComplete,
  timeInterval,
  onResendClick,
  setResendLoading,
  email,
}) => {

  const timeout = useRef();

  const [remainingTime, setRemainingTime] = useState(maxTime);


  useEffect(() => {
    if (timeout.current && remainingTime === 0) {
      clearTimeout(timeout.current);
      if (onTimerComplete) {
        onTimerComplete();
      }
    } else {
      timeout.current = setTimeout(() => {
        setRemainingTime(t => t - 1);
      }, timeInterval);
    }
    return () => {
      clearTimeout(timeout.current);
    };
  }, [onTimerComplete, remainingTime, timeInterval]);

  const handelResendClick = () => {
    setResendLoading(true);
    if (onResendClick) {
      onResendClick(remainingTime === 0);
    }

    resendEmailOtp(email).then(result => {
      if (result.data.message) {
        showMessage(result.data.message, "success");
      }
      setResendLoading(false);
      setRemainingTime(maxTime);

    }).catch(err => {
      showMessage("Something went wrong", "danger");
      setResendLoading(false);
    }
    );

  };


  return {
    handelResendClick,
    remainingTime
  };
};

export default useResendOTP;