import React, { useCallback, useEffect, useState } from "react";
import AppLayout from "../../components/AppLayout";
import { Row, Col, Container, Form, Button, Spinner } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../../components/AppLoader";
import { forgetPassword, forgetPasswordOtpCheck } from "../../actions/users";
import { showMessage } from "../../actions/constants";
import { getErrorMsg } from "../../utils";
import ResendOtp from "../ResendOtp";
import { useDispatch } from "react-redux";
import { setBreadcrumb } from "../../actions/breadcrumb";

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checkOtp, setOtpCheck] = useState(false);
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [resendLoading, setResendLoading] = useState(false);
  const dispatch = useDispatch();

  const handleFieldChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Forget Password
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }, []);

  const sendOtp = useCallback(
    async (e) => {
      if (!formData.email) {
        setLoading(false);
        showMessage("Pls enter your email", "danger");
        return false;
      }

      setFormErrors({});
      setLoading(true);
      try {
        await forgetPassword({ email: formData.email });
        showMessage(`An OTP sent to your email ${formData.email}`, "success");
        setLoading(false);
        setOtpCheck(true);
      } catch (err) {
        console.log(err.response);
        setLoading(false);
        showMessage(getErrorMsg(err), "danger");
      }
    },
    [formData]
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (formData.email === "" || (checkOtp && formData.otp === "")) {
      return false;
    }

    if (checkOtp && !formData?.otp) {
      showMessage("Pls enter an OTP!", "danger");
      return false;
    }

    if (checkOtp && formData?.otp?.length < 6) {
      showMessage("OTP must be atleast 6 characters long!", "danger");
      return false;
    }

    setLoading(true);

    if (checkOtp) {
      await forgetPasswordOtpCheck({
        email: formData.email,
        email_verification_otp: formData.otp,
      })
        .then((json) => {
          setLoading(false);
          showMessage(
            `New Password has been sent to your email ${formData.email}`,
            "success",
            5000
          );
          navigate("/login");
        })
        .catch((error) => {
          setLoading(false);
          if (error.message.indexOf("400") !== -1) {
            showMessage("OTP is invalid !", "danger");
          } else {
            showMessage("Something went wrong! Please try again!", "danger");
          }
        });
    } else {
      sendOtp();
    }
  };

  let content = (
    <>
      {loading ? <AppLoader /> : null}
      <div className="forget-sec-main">
        <div className="text-center form-sec">
          <h3 className="text-dark">Forget Password</h3>
        </div>
        <Form onSubmit={handleFormSubmit}>
          {!checkOtp ? (
            <div className="form-sec">
              <Form.Group
                as={Row}
                controlId="formPlaintextEmail"
                className="align-items-center text-center mb-4"
              >
                <Form.Label column sm="2">
                  Email
                </Form.Label>
                <Col sm="10">
                  <Form.Control
                    type="email"
                    value={formData.email}
                    placeholder="Enter email"
                    onChange={(e) => handleFieldChange(e, "email")}
                    onError={(e) => handleFieldChange(e, "email")}
                  />
                </Col>
              </Form.Group>
              {formErrors.email}
            </div>
          ) : (
            <>
              <div className="otp-sec">
                <div style={{ textAlign: "center", marginBottom: "20px" }}>
                  <p>
                    An OTP is sent to your given email address{" "}
                    <span>{formData.email}</span>
                  </p>
                </div>
              </div>
              <div className="form-sec">
                <Form.Group
                  as={Row}
                  controlId="formPlaintextOtp"
                  className="align-items-center text-center mb-4"
                >
                  <Form.Label column sm="2">
                    OTP
                  </Form.Label>
                  <Col sm="10">
                    <Form.Control
                      type="number"
                      value={formData.otp}
                      placeholder="Enter Otp"
                      onChange={(e) => handleFieldChange(e, "otp")}
                      onError={formData.otp === ""}
                    />
                  </Col>
                </Form.Group>
              </div>
            </>
          )}

          <div className="text-center">
            <Button className="my-btn" type="submit">
              {checkOtp ? "Verify OTP" : "Forget Password"}
            </Button>
          </div>
        </Form>

        {checkOtp ? (
          <div className="resend-block">
            {resendLoading ? (
              <div>
                <Spinner animation="border" role="status" size="sm" />
              </div>
            ) : (
              <ResendOtp
                maxTime={60}
                setResendLoading={setResendLoading}
                email={formData.email}
              />
            )}
          </div>
        ) : null}
        <div className="signup-sec">
          <p>
            Go to <Link to="/login">Login</Link>
          </p>
        </div>
      </div>
    </>
  );
  return (
    <AppLayout hideBanner={true}>
      <Container className=" mt-8 mb-8">
        <Row className="justify-content-md-center">
          {/* <Col md={4} sm={8} ><img className="mt-10 float-right" src="/images/deals.jpg" /></Col> */}
          <Col md={6} sm={12} className="justify-center">
            {content}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
}
