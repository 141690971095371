import React, { useState, useCallback, useEffect } from "react";
import AppLayout from "../../components/AppLayout";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import AppLoader from "../../components/AppLoader";
import { fetchUserDetails, loginByPassword } from "../../actions/users";
import { useDispatch } from "react-redux";
import { USER_LOGGED_IN, USER_DETAILS_FETCHED } from "../../reducers/types";
import { showMessage } from "../../actions/constants";
import { getErrorMsg } from "../../utils";
import { setBreadcrumb } from "../../actions/breadcrumb";

export default function Login() {
  const [isLoginInitiated, setIsLoginInitiated] = useState(false);

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();

  const handleFieldChange = (e, fieldName) => {
    setFormData({ ...formData, [fieldName]: e.target.value });
  };

  useEffect(() => {
    window.scrollTo({
      top: 150,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Login
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  const handleRedirect = useCallback(
    (e) => {
      var redirect_after_login = localStorage.getItem("redirect_after_login");

      showMessage("Logged in successfully.", "success");

      if (redirect_after_login) {
        localStorage.removeItem("redirect_after_login");
        navigate(redirect_after_login);
      } else {
        navigate("/");
      }
    },
    [navigate]
  );

  const handleFormSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      setFormErrors({});

      if (formData.password.length < 8) {
        setFormErrors({
          password: "Your password must be of minimum 8 characters.",
        });
        return false;
      }

      setIsLoginInitiated(true);

      try {
        const response = await loginByPassword(formData);
        dispatch({ type: USER_LOGGED_IN, payload: response.data });

        // TODO: customers detail api is giving cors error
        // const userDetailResponse = await fetchUserDetails(response.data.token);
        // dispatch({
        //   type: USER_DETAILS_FETCHED,
        //   payload: userDetailResponse.data,
        // });

        // x: show a success msg here
        // Redirect to home page
        // navigate("/");
        handleRedirect();
      } catch (err) {
        console.log(err.response);
        setIsLoginInitiated(false);
        showMessage(getErrorMsg(err), "danger");
      }
    },
    [dispatch, formData, handleRedirect]
  );

  const errorField = (message) => {
    return message ? (
      <div className="mt-3 mb-2 ml-1 text-red-500 text-center font-semibold">
        {message}
      </div>
    ) : null;
  };

  let content = (
    <>
      {/* {JSON.stringify(formData)} */}

      {isLoginInitiated ? <AppLoader /> : null}

      <div className="form-sec">
        <div className="text-center">
          <h3 className="text-dark">Login</h3>
        </div>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group
            as={Row}
            controlId="formPlaintextEmail"
            className="align-items-center mb-3"
          >
            <Form.Label column sm="3">
              Email*
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={formData.email}
                required={true}
                onChange={(e) => handleFieldChange(e, "email")}
              />
            </Col>
            {errorField(formErrors.email)}
          </Form.Group>

          <Form.Group
            as={Row}
            controlId="formPlaintextPassword"
            className="align-items-center mb-4"
          >
            <Form.Label column sm="3">
              Password*
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="password"
                placeholder="Password"
                value={formData.password}
                required={true}
                onChange={(e) => handleFieldChange(e, "password")}
              />
            </Col>
            {errorField(formErrors.password)}
          </Form.Group>
          <div className="text-center">
            <Button className="my-btn" type="submit">
              Submit
            </Button>
          </div>
        </Form>
        <div className="signup-sec">
          <p>
            If you do not have any account please{" "}
            <Link to="/signup">Sign Up</Link>
          </p>
        </div>

        <div className="forget-sec">
          <Link to="/forgot-password">Forgot Password?</Link>
        </div>
      </div>
    </>
  );
  return (
    <AppLayout hideBanner={true}>
      <Container className=" mt-8 mb-8">
        <Row className="justify-content-md-center">
          {/* <Col md={4} sm={8} ><img className="mt-10 float-right" src="/images/deals.jpg" /></Col> */}
          <Col md={6} sm={12} className="justify-center">
            {content}
          </Col>
        </Row>
      </Container>
    </AppLayout>
  );
}
