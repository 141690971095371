import axios from "axios";
import { server_url } from "./constants";

// import axios from "axios";
// import { server_url } from "./constants";

// export const signupUser = (mobile_number, password) => {
//   return axios.post(
//     `${server_url}/customers`,
//     { mobile_number, password },
//     {
//       headers: {
//         "content-type": "application/json",
//       },
//     }
//   );
// };

// export const forgotPassword = (mobile_number) => {
//   return axios.post(
//     `${server_url}/customers/forgot_password`,
//     { mobile_number},
//     {
//       headers: {
//         "content-type": "application/json",
//       },
//     }
//   );
// };

// export const fetchUserDetails = (token) => {
//   return axios.get(`${server_url}/customers/details`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// export const verifyMobile = (mobile_number, mobile_verification_otp) => {
//   return axios.post(
//     `${server_url}/customers/verify_mobile_otp`,
//     { mobile_number, mobile_verification_otp },
//     {
//       headers: {
//         "content-type": "application/json",
//       },
//     }
//   );
// };

export const loginUser = (username, password) => {
  return axios.post(
    `${server_url}/customers/login`,
    { username, password },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};


// export const updateUserCart = (cart_data, token) => {
//   return axios.post(
//     `${server_url}/customers/update_customer`,
//     { cart_data },
//     {
//       headers: {
//         "content-type": "application/json",
//         Authorization: `Bearer ${token}`,
//       },
//     }
//   );
// };

// export const updateUserPassword = (reqBody, token) => {
//   return axios.post(`${server_url}/customers/change_password`, reqBody, {
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };

// export const callContactUsApi = (reqBody) => {
//   return axios.post(`${server_url}/contacts`, reqBody, {
//     headers: {
//       "content-type": "application/json",
//     },
//   });
// };

// export const UpdateUserDetails = (reqBody, token) => {
//   return axios.post(`${server_url}/customers/update_customer`, reqBody, {
//     headers: {
//       "content-type": "application/json",
//       Authorization: `Bearer ${token}`,
//     },
//   });
// };




// export const signupUser = (mobile_number, password) => {
//   return axios.post(
//     `${server_url}/customers`,
//     { mobile_number, password },
//     {
//       headers: {
//         "content-type": "application/json",
//       },
//     }
//   );
// };


export const signupUser = (formData) => {
  return axios.post(
    `${server_url}/customers`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const forgetPassword = (email) => {
  return axios.post(
    `${server_url}/customers/forgot_email_password`,
    { email },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const forgetPasswordOtpCheck = (data) => {
  return axios.post(
    `${server_url}/customers/verify_email_forgot_password_otp`,
    data,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};
export const fetchUserDetails = (token) => {
  return axios.get(`${server_url}/customers/details`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const verifyMobile = (mobile_number, mobile_verification_otp) => {
  return axios.post(
    `${server_url}/customers/verify_mobile_otp`,
    { mobile_number, mobile_verification_otp },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const verifyEmail = (email, email_verification_otp) => {
  return axios.post(
    `${server_url}/customers/verify_email_otp`,
    { email, email_verification_otp },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const loginByPassword = (formData) => {
  return axios.post(
    // x: 3 : This path was wrong. The new api is "login_using_email" and not "login"
    `${server_url}/customers/login_using_email`,
    formData,
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const loginByOtp = (mobile_number, otp) => {
  return axios.post(
    `${server_url}/customers/login_using_otp`,
    { mobile_number, otp },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const sendLoginOtp = (mobile_number) => {
  return axios.post(
    `${server_url}/customers/get_login_otp`,
    { mobile_number },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const resendEmailOtp = (email) => {
  return axios.post(
    `${server_url}/customers/resend_email_otp`,
    { email },
    {
      headers: {
        "content-type": "application/json",
      },
    }
  );
};

export const updateUserCart = (cart_data, token) => {
  return axios.post(
    `${server_url}/customers/update_customer`,
    { cart_data },
    {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateUserPassword = (reqBody, token) => {
  return axios.post(`${server_url}/customers/change_password`, reqBody, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};



export const callContactUsApi = (reqBody) => {
  return axios.post(`${server_url}/contacts`, reqBody, {
    headers: {
      "content-type": "application/json",
    },
  });
};

export const UpdateUserDetails = (reqBody, token) => {
  return axios.post(`${server_url}/customers/update_customer`, reqBody, {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};