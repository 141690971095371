export const formatCompanySettings = (companySettings) => {

    const companyContactNumber = companySettings.find(elem => elem.key === "company_contact_number") ? companySettings.find(elem => elem.key === "company_contact_number").value : null;
    const companyContactEmail = companySettings.find(elem => elem.key === "company_email_address") ? companySettings.find(elem => elem.key === "company_email_address").value : null;
    const companyContactAddress= companySettings.find(elem => elem.key === "company_address") ? companySettings.find(elem => elem.key === "company_address").value : null;
    const topHeaderMsg = companySettings.find(elem => elem.key === "top_header_msg") ? companySettings.find(elem => elem.key === "top_header_msg").value : null;
    const homepageCategories = companySettings.find(elem => elem.key === "homepage_categories") ? companySettings.find(elem => elem.key === "homepage_categories").value : null;
    const homePageFeaturedProductsMoreButtonUrl = companySettings.find(elem => elem.key === "homepage_featured_products_more_button_url") ? companySettings.find(elem => elem.key === "homepage_featured_products_more_button_url").value : null;
    const detailsAboutCompany = companySettings.find(elem => elem.key === "details_about_company") ? companySettings.find(elem => elem.key === "details_about_company").value : null;

    const socialUrls = {
        facebook: companySettings.find(elem => elem.key === "social_links_facebook") ? companySettings.find(elem => elem.key === "social_links_facebook").value : '#',
        twitter: companySettings.find(elem => elem.key === "social_links_twitter") ? companySettings.find(elem => elem.key === "social_links_twitter").value : '#',
        instagram: companySettings.find(elem => elem.key === "social_links_instagram") ? companySettings.find(elem => elem.key === "social_links_instagram").value : '#',
        linkedin: companySettings.find(elem => elem.key === "social_links_linkedin") ? companySettings.find(elem => elem.key === "social_links_linkedin").value : '#',
        playStore: companySettings.find(elem => elem.key === "social_links_play_store") ? companySettings.find(elem => elem.key === "social_links_play_store").value : '#',
    }

    return { companyContactNumber, companyContactEmail,companyContactAddress, socialUrls, homepageCategories,homePageFeaturedProductsMoreButtonUrl,detailsAboutCompany, topHeaderMsg };
}