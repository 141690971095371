

export const getContentByCategoryName = (contents, categoryName) => contents.filter(content => content.category === categoryName);

export const getContentByName = (contents, name, defaultValue) => contents.find(content => content.name === name) || defaultValue;


export const cartData_local_storage_key = 'rajvasantexport_cart_data';
export const authKey_local_storage_key = 'rajvasantexport_authToken';




