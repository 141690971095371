import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppLayout from "../../components/AppLayout";
import { callOrdersCheckoutApi } from "../../actions/order";
import Address from "./Address";
import AppLoader from "../../components/AppLoader";
import { CHECKOUT_DONE } from "../../reducers/types";
import { Link, useNavigate } from "react-router-dom";
import { setBreadcrumb } from "../../actions/breadcrumb";


function Shipping() {
  const cartState = useSelector((state) => state.cart);
  const userState = useSelector((state) => state.user);
  const { checkout } = useSelector((state) => state.order);
  const currency = useSelector((state) => state.meta.currentCurrency);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    window.scrollTo({
      top: 200,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    let breadcrumb = (
      <>
        <Link to="/">Home</Link>
        <Link className="active" to="#">
          / Shipping
        </Link>
      </>
    );
    setBreadcrumb(dispatch, breadcrumb);
  }, [dispatch]);

  const handleAddressSubmit = useCallback(
    async (customer) => {
      const items = cartState.data.map((d) => {
        return {
          item_id: d.id,
          quantity: d.qty,
        };
      });

      let coupon_code = ""; //TODO: to complete this
      let customer_remarks = ""; //TODO: to complete this
      let currency_code = currency?.code;

      callOrdersCheckoutApi(dispatch, userState.data.token, {
        customer,
        items,
        customer_remarks,
        coupon_code,
        currency_code,
      });
    },
    [dispatch, userState.data.token, cartState.data, currency]
  );

  useEffect(() => {
    if (checkout.data && checkout.data.order && checkout.data.order.id) {
      navigate(`/checkout/${checkout.data.order.id}`, {
        state: { shippingData: checkout.data },
      });
    }
  }, [checkout, navigate]);

  const handleBackOperation = useCallback(() => {
    dispatch({ type: CHECKOUT_DONE });
    navigate("/cart");
  }, [dispatch, navigate]);

  let content = null;

  content = (
    <>
      {checkout.loading ? <AppLoader /> : null}
      <Address
        handleBackOperation={handleBackOperation}
        handleAddressSubmit={handleAddressSubmit}
        cartState={cartState}
      />
    </>
  );

  return (
    <AppLayout
      hideSubscribeSection={true}
      hideBanner={true}
      hidePhoneDetail={true}
    >
      <div className={`myContainer my-5`}>{content}</div>
    </AppLayout>
  );
}

export default Shipping;
